import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  newNotifications: false,
  notifications: []
}


export const name = 'notifications';

const notificationsSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeNotifications: (state, action) => {
      state.initialized = action.payload.initialized;
      state.newNotifications = action.payload.newNotifications;
      state.notifications = action.payload.notification;
    },

    setNewNotifications: (state, action) => {
      state.newNotifications = action.payload.newNotifications;
    },
  },
});

export const { actions, reducer } = notificationsSlice;
export default notificationsSlice;


// // ----
// export function notificationsReducer(state = initialSate, action) {

//   // if( action.type === 'initializeNotifications') {
//   //   return {
//   //     ...state,
//   //     initialized: action.payload.initialized,
//   //     newNotifications: action.payload.newNotifications,
//   //     notifications: action.payload.notifications
//   //   };
//   // }

//   if( action.type === 'setNewNotifications') {
//     return {
//       ...state,
//       newNotifications: action.payload.newNotifications
//     }
//   }

//   return state;
// }