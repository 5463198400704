import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  series: []
}

export const name = 'allSeries';

const allSeriesSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeAllSeries: (state, action) => {
      state.initialized = action.payload.initialized;
      state.series = action.payload.series;
    },
  },
});

export const { actions, reducer } = allSeriesSlice;
export default allSeriesSlice;


// // ----
// export function AllSeriesReducer(state = initialSate, action) {

//   if( action.type === 'initializeAllSeries') {
//     return {
//       ...state,
//       initialized: action.payload.initialized,
//       series: action.payload.series
//     }
//   }

//   return state;
// }