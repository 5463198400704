import React from "react";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import '../../styles/start.css';

import Modal from "../utilities/modal";
import { getProfile, updateLearnerProfile } from "../utilities/auth/authApi";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import StartLeftColumn from "./startLeftColumn";
import AccountSetupForm from "./setupAccountForm";
import UnauthedStartHeader from "./unauthedStartHeader";

import profileSlice from "../../store/profile";


function Welcome() {

  // Dispatch functions
  const dispatch = useDispatch();

  function initializeProfile(data) {
    // dispatch({ type: 'initializeProfile', payload: data });
    dispatch(profileSlice.actions.initializeProfile(data));
  }

  function updateField(field, value) {
    // dispatch({ type: 'updateField', payload: {field, value} });
    dispatch(profileSlice.actions.updateField({field, value}));
  }


  // Define variables
  const { slug } = useParams();
  const navigate = useNavigate();

  const [learningMotivations, setLearningMotivations] = useState([]);
  const [mainGoal, setMainGoal] = useState(null);
  const [languageLevel, setLanguageLevel] = useState(null);
  const [reminderFrequency, setReminderFrequency] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(null);
  const [modal, setModal] = useState(null);

  const promoCode = useSelector((state) => state.profile.promoCode);
  const hasPremiumSubscription = useSelector((state) => state.profile.hasPremiumSubscription);


  // Component load actions
  useEffect(() => {

    // fadeInMainBody();

    getProfile().then((response) => {
      if( response.data ) {

        // See if the learner was auto upgraded
        // show the auto upgrade modal if so
        const subscriptionData = response.data.subscription;
        if( subscriptionData?.autoUpgrade ) {
          setModal(
            <Modal
              closeModal={closeModal}
              title={subscriptionData.autoUpgradeMessageTitle}
              body={subscriptionData.autoUpgradeMessageBody}
              bodyAsHtml={true}
              closeButton={true}
            />
          );
        }

        initializeProfile(response.data);

        // Specific tracking for onboarding steps
        const registerType = slug ? 'orgRegister' : 'regularRegister';

        const mixpanelParams = { 'registerType': registerType};

        Mixpanel.identify(response.data.id);
        Mixpanel.people.set({
          $first_name: response.data.firstName
        });

        Mixpanel.track('Notice: Successful Sign Up', mixpanelParams);

        // Set the register type in Redux so we can use it in a bit
        updateField('registerType', registerType);

      }
    });

  }, []);


  // Define functions
  function closeModal() {
    setModal(null);
  }

  function submitAndContinue() {

    let mounted = true;
    setButtonLoading(true);

    let languageLevelId;
    if( languageLevel === 'Beginner' ) {languageLevelId = 1}
    if( languageLevel === 'Intermediate' ) {languageLevelId = 2}
    if( languageLevel === 'Advanced' ) {languageLevelId = 3}

    const payload = {
      languageLevel: languageLevelId,
      learningMotivations: JSON.stringify(learningMotivations),
      mainGoal: mainGoal,
      reminderFrequency: reminderFrequency
    }

    updateLearnerProfile(payload).then((response) => {
      let path = '/welcome-choose-membership';
      if( slug ) { path = `/register/${slug}/choose-membership`}

      if( hasPremiumSubscription ) {

        // If they were auto upgraded, go stright to the mic test
        path = '/welcome-mic-test';
        if( slug ) { path = `/register/${slug}/complete`}

      } else {
        let attributes = [];

        if( payload.mainGoal ) { attributes.push(`mainGoal=${payload.mainGoal}`) }
        if( payload.reminderFrequency ) { attributes.push(`reminderFrequency=${payload.reminderFrequency}`) }
        if( promoCode ) { attributes.push(`promoCode=${promoCode}`) }

        const pathAttributes = attributes.join('&');

        path = `${path}?${pathAttributes}`;
      }

      mounted = false;
      navigate(path);
    }).finally(() => {
      if( mounted ) {
        setTimeout( setButtonLoading(false), 400);
      }
    });
  }


  return (
    <div class='wrapper'>
      {modal}
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <UnauthedStartHeader
                hideTitle={true}
                subTitle={"Let's set up your account"}
              />
              <AccountSetupForm
                submitAndContinue={submitAndContinue}
                learningMotivations={learningMotivations}
                setLearningMotivations={setLearningMotivations}
                mainGoal={mainGoal}
                setMainGoal={setMainGoal}
                languageLevel={languageLevel}
                setLanguageLevel={setLanguageLevel}
                reminderFrequency={reminderFrequency}
                setReminderFrequency={setReminderFrequency}
                buttonLoading={buttonLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
