import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  currentScrollPosition: null,
  posts: []
}

export const name = 'posts';

const postsSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializePosts: (state, action) => {
      state.initialized = action.payload.initialized;
      state.posts = action.payload.posts;
    },

    addNewPost: (state, action) => {
      // We want the new post at the top of the list
      const existingPosts = state.posts.reverse();
      const newPosts = existingPosts.concat(action.payload).reverse();

      state.posts = newPosts;
    },

    updateLikeCount: (state, action) => {
      const updatedPosts = state.posts;
      const updateObject = action.payload.updateObject;
      const thisPost = updatedPosts.find(x => x.id === updateObject.id);

      if( thisPost ) {
        thisPost.like_count = updateObject.newLikeCount;
        thisPost.learner_like_count = updateObject.learnerLikeCount;
      }

      state.posts = updatedPosts;
    },

    incrementCommentCount: (state, action) => {
      const updatedPosts = state.posts;
      const id = action.payload.id;
      const thisPost = updatedPosts.find(x => x.id === parseInt(id));

      if( thisPost ) {
        if( thisPost.comment_count ) {
          thisPost.comment_count += 1;
        } else {
          thisPost.comment_count = 1;
        }
      }

      state.posts = updatedPosts;
    },

    setCurrentScrollPosition: (state, action) => {
      state.currentScrollPosition = action.payload.currentScrollPosition;
    },

  },
});

export const { actions, reducer } = postsSlice;
export default postsSlice;


// // ----
// export function postsReducer(state = initialSate, action) {

//   // if( action.type === 'initializePosts') {
//   //   return {
//   //     ...state,
//   //     initialized: action.payload.initialized,
//   //     posts: action.payload.posts
//   //   };
//   // }

//   // if( action.type === 'addNewPost') {
//   //   // We want the new post at the top of the list
//   //   const existingPosts = state.posts.reverse();
//   //   const newPosts = existingPosts.concat(action.payload).reverse();

//   //   return {
//   //     ...state,
//   //     posts: newPosts
//   //   };
//   // }

//   if( action.type === 'updateLikeCount') {
//     const updatedPosts = state.posts;
//     const updateObject = action.payload.updateObject;
//     const thisPost = updatedPosts.find(x => x.id === updateObject.id);

//     if( thisPost ) {
//       thisPost.like_count = updateObject.newLikeCount;
//       thisPost.learner_like_count = updateObject.learnerLikeCount;
//     }

//     return {
//       ...state,
//       posts: updatedPosts
//     };
//   }

//   if( action.type === 'incrementCommentCount') {
//     const updatedPosts = state.posts;
//     const id = action.payload.id;
//     const thisPost = updatedPosts.find(x => x.id === parseInt(id));

//     if( thisPost ) {
//       if( thisPost.comment_count ) {
//         thisPost.comment_count += 1;
//       } else {
//         thisPost.comment_count = 1;
//       }
//     }

//     return {
//       ...state,
//       posts: updatedPosts
//     };
//   }

//   if( action.type === 'setCurrentScrollPosition') {
//     return {
//       ...state,
//       currentScrollPosition: action.payload.currentScrollPosition
//     };
//   }




//   return state;
// }