import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  headerData: {},
  lessonGroups: [],
  // bonusLessons: [],
  stepData: null
}

export const name = 'dashboard';

const dashboardSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeDashboardData: (state, action) => {
      state.initialized = true;
      state.headerData = action.payload.headerData;
      state.lessonGroups = action.payload.lessonGroups;
      state.stepData = action.payload.stepData;
    },

    clearDashboardData: (state, action) => {
      state.initialized = false;
      state.headerData = initialState.headerData;
      state.lessonGroups = initialState.lessonGroups;
      state.stepData = initialState.stepDat;
    },

    incrementProfileSetup: (state, action) => {
      let newHeaderData = state.headerData;
      const profileSetup = newHeaderData.profileSetup;
      profileSetup[action.payload.field] = action.payload.value;

      // Null the object if all three are done
      if( (profileSetup.askAvatar != true) && (profileSetup.askCountry != true) && (profileSetup.askNativeLanguage != true) ) {
        newHeaderData = null;
      }

      state.headerData = newHeaderData;
    }
  },
});

export const { actions, reducer } = dashboardSlice;
export default dashboardSlice;