import { useState } from 'react';

import { updatePersonalInformation } from "./auth/authApi";
import alertMessage from './alertMessage';
import { Mixpanel } from './analytics/mixpanelHelpers';

import Avatar from "react-avatar-edit";
import DefaultAvatarPicker from "./defaultAvatarPicker";


function AvatarImageUploadModal(props) {

  // Define variables
  const [showAvatarPicker, setShowAvatarPicker] = useState(false);
  const [defaultAvatar, setDefaultAvatar] = useState(null);
  const onBeforeFileLoad = props.onBeforeFileLoad;
  const isSaving = props.isSaving;
  const saveData = props.saveData;
  const onCrop = props.onCrop;
  const updateField = props.updateField;


  // Define functions
  function handleDefaultAvatar() {
    saveDefaultData();
    updateField('avatarUrl', defaultAvatar);
  }

  function saveDefaultData() {
    const payload = {
      user: {['avatar_url_stored']: defaultAvatar}
    }

    updatePersonalInformation(payload).then((response) => {
      if( response && response.data ) {
        if( response.data.success ) {
          // Do nothing
        } else {
          alertMessage(response.data.error);
        }
      } else {
        alertMessage('Sorry, there was a problem', 'error');
      }
    });

    Mixpanel.track('Event: Header Avatar picker - save data');
  }


  // Screen components
  let bodyElement;
  if( showAvatarPicker ) {

    bodyElement = (
      <div class='flex-column'>
        <DefaultAvatarPicker setDefaultAvatar={setDefaultAvatar} />

        <div style={{marginBottom: '30px'}}>
          <button
            class='profile-setup-avatar-picker-button'
            onClick={() => setShowAvatarPicker(!showAvatarPicker)}
          >or upload your own image</button>
        </div>

        <button
          class='button setup-profile-upload-button'
          onClick={() => handleDefaultAvatar()}
          disabled={!defaultAvatar}
        >Save</button>
      </div>
    );

  } else {

    bodyElement = (
      <div
      class='flex-column'
      style={{overflow: 'hidden'}}
    >
      <Avatar
        width={250}
        height={200}
        onCrop={onCrop}
        onBeforeFileLoad={onBeforeFileLoad}
        src={''}
        exportAsSquare={true}
        exportMimeType={'image/jpeg'}
      />

      <button
        class='profile-setup-avatar-picker-button'
        onClick={() => setShowAvatarPicker(!showAvatarPicker)}
      >or pick an Avatar from our list</button>

      <br />

      <button
        class={`button ${isSaving ? 'is-loading' : null}`}
        disabled={isSaving}
        onClick={() => saveData()}
      >
        Save
      </button>
    </div>
    );

  }


  return (
    <>
      {bodyElement}
    </>
  );
}

export default AvatarImageUploadModal;