import React from "react";
import { useState } from 'react';
import { Editor, EditorProvider } from 'react-simple-wysiwyg';


import FeedbackElement from "./feedbackElement";


function InputTextareaBasicRichText(props) {

  // Define variables
  let label = '';
  if( props.label ) { label = <label class="label">{props.label}</label>}

  const name = props.name || '';
  let placeholder = props.placeholder || '';
  const required = props.required ? true : false;
  const textareaId = name + Math.round(Math.random() * 100, 2);
  const onFocus = props.handleOnFocus || undefined;
  const commentableType = props.commentableType;
  const author = props.author;

  const [html, setHtml] = useState('');

  const textarea = document.getElementById('text-editor-basic');


  // Define functions
  function onChange(event) {
    setHtml(event.target.value);
  }

  function addText(event) {
    const newText = event.target.innerText.replace('...', '');
    const newValue = html + ' ' + newText;
    setHtml(newValue);
    if( textarea ) {
      textarea.focus();
    }
  }


  // Screen components
  let feedbackElement;
  if( commentableType === 'Response' ) {
    feedbackElement = <FeedbackElement author={author} addText={addText} />;
    placeholder = 'Write your feedback ...';
  }


  return (
    <div class="field input-textarea">

      {feedbackElement}

      {label}
      <div class="control">
      <EditorProvider>
        <Editor
          value={html}
          onChange={onChange}
          onFocus={onFocus}
          id={'text-editor-basic'}
          data-placeholder={placeholder}
        ></Editor>
      </EditorProvider>

        <textarea
          id={textareaId}
          name={name}
          class='shadow-textarea'
          required={required}
          minLength="4"
          value={html}
          readOnly={true}
        ></textarea>

        <div class='emoji-button-wrapper'>
          <button type='button' onClick={addText}>&#x1F525;</button>
          <button type='button' onClick={addText}>&#x1F680;</button>
          <button type='button' onClick={addText}>&#x1F60E;</button>
        </div>

      </div>
    </div>
  );
}

export default InputTextareaBasicRichText;