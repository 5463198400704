import React from "react";
import RenderHtmlText from "../utilities/renderhtmltext";


function Modal(props) {

  // Define variables
  const windowClass = 'window-modal ' + props.windowClass;
  const closeModal = props.closeModal;
  const title = props.title;
  const centerTitle = props.centerTitle;
  const subtitle = props.subtitle;
  const bodyAsHtml = props.bodyAsHtml;
  const body = props.body;
  const bodyElement = bodyAsHtml ? <RenderHtmlText string={body} /> : body;

  const closeIcon = props.closeIcon;
  const closeButton = props.closeButton;


  // Screen components
  let titleElement;
  if( title ) {
    titleElement = <p class='modal-title'>{props.title}</p>;
  }

  let subtitleElement;
  if( subtitle ) {
    subtitleElement = <div class='window-modal-subtitle'>{subtitle}</div>;
  }

  let closeIconElement;
  if( closeIcon ) {
    closeIconElement = <button class='icon icon-close' onClick={closeModal}></button>;
  }

  let closeButtonElement;
  if( closeButton ) {
    closeButtonElement = (
      <div class='window-modal-footer'>
        <button class='basic-button' title='Close and continue' onClick={closeModal}>Continue</button>
      </div>
    );
  }

  let spacerElement;
  if( centerTitle ) {
    spacerElement = <span class='icon'>&nbsp;</span>;

    if( !closeIcon ) {
      closeIconElement = spacerElement;
    }
  }


  return (
    <div class='window-modal-wrapper'>
      <div class={windowClass}>

        <div class='window-modal-header'>
          {spacerElement}
          {titleElement}
          {closeIconElement}
        </div>

        {subtitleElement}

        <div class='window-modal-body'>
          {bodyElement}
        </div>


        {closeButtonElement}

      </div>
    </div>
  );
};

export default Modal;