import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import alertMessage from "../utilities/alertMessage";
import Modal from "../utilities/modal";
import ScoreDonut from "../utilities/learning_module/scoredonut";
import ProfileToggle from "../profile/profiletoggle";

import translations from "../../functions/translations";
import { languageCodes } from "../utilities/languagecodes";


function AiTalkMessageScore(props) {

  // Define variables
  const nativeLanguage = useSelector((state) => state.profile.nativeLanguage);
  const language = languageCodes.find(l => l.value === nativeLanguage);
  const [translatedFeedback, setTranslatedFeedback] = useState(null);
  const [fluencyExplanationText, setFluencyExplanationText] = useState('');
  const [relevancyExplanationText, setRelevancyExplanationText] = useState('');
  const [translateToggle, setTranslateToggle] = useState(false);
  const [translateLoading, setTranslateLoading] = useState(false);

  const translatedFeedbackInitial = props.translatedFeedback;
  const setTranslatedFeedbackInitial = props.setTranslatedFeedback;
  const closeModal = props.closeModal;
  const responseObject = props.responseObject;

  const overallScore = responseObject.overall.percent;
  const fluencyWord = responseObject.fluency.word;
  const fluencyScore = responseObject.fluency.percent;
  const fluencyExplanation = responseObject.fluency.explanation;
  const relevancyWord = responseObject.relevance.word;
  const relevancyScore = responseObject.relevance.percent;
  const relevancyExplanation = responseObject.relevance.explanation;
  const tips = responseObject.tips;

  let subtitle = `${overallScore}%`;


  // Component load actions
  useEffect(() => {
    setFluencyExplanationText(fluencyExplanation);
    setRelevancyExplanationText(relevancyExplanation);

    if( translatedFeedbackInitial ) {
      setTranslatedFeedback(translatedFeedbackInitial);
    }
  }, []);


  // Define functions
  function tryToggleTranslate() {
    if( translateToggle ) {
      setFluencyExplanationText(fluencyExplanation);
      setRelevancyExplanationText(relevancyExplanation);
      setTranslateToggle(false);
    } else {
      translate();
    }
  }

  function translate() {

    // Log the event
    Mixpanel.track('Event: Translate AI Talk or Booster Feedback');

    if( translatedFeedback ) {
      populateTranslatedFeedback(translatedFeedback);
      return;
    }

    setTranslateLoading(true);

    const payload = {
      language: language.name,
      translations: {
        fluency: fluencyExplanation,
        relevance: relevancyExplanation
      }
    };

    translations(payload).then(function (response) {
      if( response.success ) {
        const data = response.data;

        // We need to sed the feedback inside the componenet and in the parent
        // becuase while the modal is open, it won't register the updated data in the paret
        // so the inside compoennt state handles while the componet is open and not closed
        // and the parent handles if the modal is re-opened
        setTranslatedFeedback(data);
        setTranslatedFeedbackInitial(data);
        populateTranslatedFeedback(data);
      } else {
        alertMessage('Sorry, we could not translate the feedback.')
      }
    }).finally(() => {
      setTranslateLoading(false);
    });
  }

  function populateTranslatedFeedback(data) {
    if( data.fluency ) { setFluencyExplanationText(data.fluency) }
    if( data.relevance ) { setRelevancyExplanationText(data.relevance) }

    setTranslateToggle(true);
  }


  // Screen componetns
  let translateElement;

  if( language ) {

    let translateLoadingElement;
    if( translateLoading ) {
      translateLoadingElement = <div class='button clean-button is-loading'></div>;
    }

    translateElement = (
      <div class='flex-row ai-talk-translate-toggle-container'>
        <ProfileToggle
          label={'Translate feedback'}
          value={translateToggle}
          handlePreferenceChange={tryToggleTranslate}
          disabled={translateLoading}
        />

        {translateLoadingElement}
      </div>
    );
  }

  const body = (
    <div>

      <div class='ai-talk-score-row'>
        <div class='flex-row ai-talk-score-row-header'>
          <p><strong>Fluency</strong></p>
          <div class='flex-row'>
            {fluencyWord}
            <ScoreDonut scorePercent={fluencyScore} />
          </div>
        </div>
        <p>{fluencyExplanationText}</p>
      </div>

      <div class='ai-talk-score-row'>
        <div class='flex-row ai-talk-score-row-header'>
          <p><strong>Relevance</strong></p>
          <div class='flex-row'>
            {relevancyWord}
            <ScoreDonut scorePercent={relevancyScore} />
          </div>
        </div>
        <p>{relevancyExplanationText}</p>
      </div>

      <div class='ai-talk-score-spacer'></div>

      <div class='ai-talk-score-row'>
        <div class='flex-row ai-talk-score-row-header'>
          <p><strong>Example response</strong></p>
        </div>
        <p>{tips}</p>
      </div>

      {translateElement}
    </div>
  );


  return (
    <Modal
      windowClass={'aitalk-message-modal'}
      closeModal={closeModal}
      // title={'Fluency Score'}
      title={'Score'}
      centerTitle={true}
      subtitle={subtitle}
      body={body}
      closeIcon={true}
    />
  );
}

export default AiTalkMessageScore;