import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import '../../styles/booster.css';

import toast from 'react-hot-toast';
import { reportError } from "../../functions/sentry";
import apiCall from '../utilities/auth/apiCall';
import { getBooster, updateSavedBoosters, logExerciseStart } from '../utilities/auth/authApi';

import BoosterHeader from './boosterHeader';
import ExpressionRelated from '../expression/screens/expressionRelated';
import NoAccess from "../utilities/noAccess";
import BoosterDefinitions from './boosterDefinitions';
import BoosterPractice from './boosterPractice';
import BoosterTalk from './boosterTalk';

import lessonSlice from '../../store/lesson';
import boosterSlice from '../../store/booster';
import savedLessonsSlice from '../../store/savedlessons';


function BoosterScreen() {

  // Dispatch
  const dispatch = useDispatch();

  function setActivePlaying(status) {
    // dispatch({ type: 'setActivePlaying', payload: status});
    dispatch(lessonSlice.actions.setActiveRecording(status));
  }

  function setBooster(payload) {
    // dispatch({ type: 'setBooster', payload: payload});
    dispatch(boosterSlice.actions.setBooster(payload));
  }

  function addResponseBooster(responseObject) {
    // dispatch({ type: 'addResponseBooster', payload: responseObject});
    dispatch(boosterSlice.actions.addResponseBooster(responseObject));
  }

  function setExerciseIdBooster(id) {
    // dispatch({ type: 'setExerciseIdBooster', payload: id});
    dispatch(boosterSlice.actions.setExerciseIdBooster(id));
  }

  function setIsSavedBooster(value) {
    // dispatch({ type: 'setIsSavedBooster', payload: value});
    dispatch(boosterSlice.actions.setIsSavedBooster(value));
  }

  function invalidateSavedLessons() {
    // dispatch({ type: 'invalidateSavedLessons'});
    dispatch(savedLessonsSlice.actions.invalidateSavedLessons());
  }


  // Define variables
  const { id } = useParams();
  const isInitialized = useSelector((state) => state.booster.isInitialized);
  const booster = useSelector((state) => state.booster.lessonData);
  const messages = useSelector((state) => state.booster.messages);
  const isSaved = useSelector((state) => state.booster.isSaved);
  const avatarUrl = useSelector((state) => state.profile.avatarUrl);

  const learnerHasAccessToLesson = booster.learnerHasAccessToLesson;
  const title = booster.title;
  const phrases = booster.phrases;
  const relatedLessons = booster.relatedLessons;
  const learnerSpeakingTurns = booster.learnerSpeakingTurns;
  const extras = booster.extras;
  const messageCount = messages.filter(m => m.role === 'user').length;

  const [progressBarData, setProgressBarData] = useState([]);
  const [step, setStep] = useState(1); // 1 - Definitions, 2 - Phrases, 3 - Talk
  const [phraseStep, setPhraseStep] = useState(0);
  const [talkStep, setTalkStep] = useState(0);
  const [translateToggle, setTranslateToggle] = useState(false);
  const [translateContent, setTranslateContent] = useState(null);


  // Component load actions
  useEffect(() => {

    setActivePlaying(false);

    let fetchData = false;
    if( booster?.id !== Number(id) ) { fetchData = true }
    if( !isInitialized ) { fetchData = true }

    if( fetchData ) {
      apiCall(getBooster, id).then((response) => {

        if( response.success ) {
          const lessonInfo = response.data.booster;

          const reduxInfo = {
            id: id,
            lessonData: lessonInfo,
            isSaved: lessonInfo.isSaved,
            isIelts: lessonInfo.isIelts,
            messages: lessonInfo.starterMessages
          };

          setBooster(reduxInfo);
        } else {
          toast.error(response.error);
          reportError(response.error);
        }

      });
    }

    buildProgressBarData();

  }, [id, booster, step, phraseStep, messageCount]);


  // Define functions
  function buildProgressBarData() {
    if( isInitialized ) {
      const progressData = [
        {type: 'active'},
        {type: 'spacer'},
      ];

      phrases.map((phrase, index) => {
        const thisType = (phraseStep >= index+1) ? 'active' : 'inactive';
        progressData.push({type: thisType});
      });

      progressData.push({type: 'spacer'});

      [...Array(learnerSpeakingTurns+1)].forEach((_, index) => {
        // const thisType = (talkStep >= index+1) ? 'active' : 'inactive';
        const thisType = (step === 3 && messageCount >= index+1) ? 'active' : 'inactive';
        progressData.push({type: thisType});
      });

      setProgressBarData(progressData);
    }
  }

  function saveLesson() {
    const payload = {
      id: id,
      is_saved: isSaved
    }

    apiCall(updateSavedBoosters, payload).then((response) => {
      if( response.success ) {
        setIsSavedBooster(response.data.isSaved);
        invalidateSavedLessons();
      } else {
        toast.error(response.error);
      }
    });
  }

  function logStart() {

    const payload = {
      lesson_id: id,
      started: true,
      lesson_type: 'Booster'
    }

    apiCall(logExerciseStart, payload).then((response) => {
      if( response.success ) {
        setExerciseIdBooster(response.data.exerciseId);
      }
    });
  }

  function handleTranscript(responseObject) {
    // Add the Text
    responseObject.text = responseObject.providedText;
    addResponseBooster(responseObject);
  }

  function nextStep() {
    if( step === 1 ) {
      logStart();

      setPhraseStep(phraseStep + 1);
      setStep(2);
    } else if( step === 2 ) {
      if( phraseStep === phrases.length) {
        setTalkStep(talkStep + 1);
        setStep(3);
      } else {
        setPhraseStep(phraseStep + 1);
      }
    } else if( step === 3 ) {
      setTalkStep(talkStep + 1);
    }
  }


  // Screen components
  let contentElement;
  if( step === 1 ) {
    contentElement = (
      <BoosterDefinitions
        isInitialized={isInitialized}
        id={id}
        booster={booster}
        nextStep={nextStep}
        translateToggle={translateToggle}
        setTranslateToggle={setTranslateToggle}
        translateContent={translateContent}
        setTranslateContent={setTranslateContent}
      />
    );
  } else if( step === 2 ) {
    contentElement = (
      <BoosterPractice
        id={id}
        booster={booster}
        nextStep={nextStep}
        phraseStep={phraseStep}
        avatarUrl={avatarUrl}
        handleTranscript={handleTranscript}
        translateToggle={translateToggle}
        setTranslateToggle={setTranslateToggle}
        translateContent={translateContent}
        setTranslateContent={setTranslateContent}
        extras={extras}
      />
    );
  } else if( step === 3 ) {
    contentElement = (
      <BoosterTalk
        id={id}
        booster={booster}
        nextStep={nextStep}
        talkStep={talkStep}
        avatarUrl={avatarUrl}
        setExerciseIdBooster={setExerciseIdBooster}
      />
    );
  }

  if( !learnerHasAccessToLesson ) {
    contentElement = <NoAccess />;
  }

  let skipElement;
  // if( process.env.NODE_ENV === 'development' ) {
  //   skipElement = (
  //     <div>
  //       <button onClick={() => setStep(3)}>Skip</button>
  //     </div>
  //   );
  // }


  return (
    <div class='wrapper'>
      <div class='lesson-body booster'>

        <div class='lesson-left-column'>
          <p class='related-lessons-title'>Related Lessons</p>
          <ExpressionRelated
            relatedLessons={relatedLessons}
          />
        </div>

        <div class='lesson-right-column'>
          <BoosterHeader
            title={title}
            saveLesson={saveLesson}
            progressBarData={progressBarData}
          />

          {skipElement}
          {contentElement}
        </div>

      </div>
    </div>
  );
}

export default BoosterScreen;