import { useState } from "react";

import { Mixpanel } from './analytics/mixpanelHelpers';
import DashboardProfileSetupModal from "./dashboardProfileSetupModal";


function DashboardProfileSetup(props) {

  // Define variables
  const [modal, setModal] = useState(null);
  const profileSetup = props.profileSetup;
  const count = [profileSetup.askAvatar, profileSetup.askCountry, profileSetup.askNativeLanguage].filter( element => element != true ).length


  // Define functions
  function handleClick() {
    setModal(<DashboardProfileSetupModal profileSetup={profileSetup} closeModal={closeModal} />);
    Mixpanel.track('Event: Profile Setup - open modal');
  }

  function closeModal() {
    setModal(null);
  }


  return (
    <div class='flex-row profile-setup-wrapper'>
      {modal}
      <button type='button' class='profile-setup-container' onClick={() => handleClick()}>
        <p>
          Build your profile
          <small>{count}/3</small>
        </p>
      </button>
    </div>
  );
}

export default DashboardProfileSetup;