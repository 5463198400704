import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import CommunityIndex from "../utilities/learning_module/community/index";
import ResponseComments from  "../utilities/learning_module/community/responsecomments";
import ModuleContentResponses from  "../utilities/learning_module/community/modulecontentresponses";

import HeaderLesson from "./headerlesson";
import LessonScreenSwitcher from './lessonScreenSwitcher';
import LeftColumn from "../utilities/learning_module/leftcolumn";

import loadModule from '../../functions/loadmodule.js';

import lessonSlice from '../../store/lesson.js';


function LessonCommunity() {

  const dispatch = useDispatch();

  const { id, moduleContentId, responseId } = useParams();

  currentLesson = useSelector((state) => state.lesson.currentLesson);
  const currentLessonId = useSelector((state) => state.lesson.currentLessonId);


  useEffect(() => {

    if( !currentLessonId || currentLessonId !== id ) {
      // Need to fetch lesson data

      loadModule(id).then((response) => {
        if( response.data ){
          const lesson = response.data;

          // Update the redux store
          const payload = {
            id: id,
            isSaved: lesson.isSaved,
            lesson: lesson,
            questionList: lesson.moduleContent,
            activeQuestions: [lesson.moduleContent[0]],
            currentQuestionNumber: 0
          };

          // dispatch({ type: 'setLesson', payload: payload });
          dispatch(lessonSlice.actions.setLesson(payload));
        }
      }).catch((error) => {
        console.log("Error loading module data");
        console.log(error);
      });
    }

  });
  // }, []);

  let communityComponent;

  if( responseId ) {
    communityComponent = <ResponseComments />;
  } else if( moduleContentId ) {
    communityComponent = <ModuleContentResponses />;
  } else {
    communityComponent = <CommunityIndex />;
  }

  return (
    <div class='wrapper'>
      <div class='lesson-body'>

        <LeftColumn />

        <div class='lesson-right-column'>

          <div class='lesson-header-content-container'>
            <HeaderLesson />
            <LessonScreenSwitcher
              id={id}
              currentScreen={'community'}
            />
          </div>


          <section class='body-content community'>
            <br />
            {communityComponent}
          </section>
        </div>
      </div>
    </div>
  );
}

// Define variables we need globally
let currentLesson;

export default LessonCommunity;