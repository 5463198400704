import axios from 'axios';
import { reportError } from "./sentry";


const grammarCheck = async (transcript, transcriptChunks) => {

  if( transcriptChunks ) {
    return transcriptChunks;
  }

  let apiUrl;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // apiUrl = "http://localhost:8080/api/grammar_check";
    // apiUrl = "https://microservice.flowspeak.io/api/grammar_check";
    const dummyTranscriptChunks = makeDummyTranscriptChunks();
    return dummyTranscriptChunks;
  } else {
    apiUrl = "https://microservice.flowspeak.io/api/grammar_check";
  }


  const url = encodeURI(apiUrl);


  const formData = new FormData();
  formData.append('apiKey', "fs000000000003044092320002333");

  formData.append(`transcript`, transcript);

  const config = {
    method: 'post',
    url: url,
    headers: { "Content-Type": "multipart/form-data" },
    data : formData,
  };

  return axios(config).then((response) => {

    if( response?.data?.name === 'Error' ) {
      reportError(response.data.message);
      return [];
    }

    const edits = response?.data?.edits || [];
    const thisGrammarErrors = [];

    edits.map((object, index) => {
      const data = {
        type: object.general_error_type,
        offset: object.sentence_start + object.start,
        length: object.end - object.start,
        description: object.replacement
      }

      thisGrammarErrors.push(data);
    });

    const transcriptChunks = createNodes(thisGrammarErrors, transcript);
    return transcriptChunks;

  }).catch((error) => {
    console.log('error: ', error);
    reportError(error);
  });

}

export default grammarCheck;





const intoPairs = xs => xs.slice(1).map((x, i) => [xs[i], x]);

const breakAt = (places, str) => intoPairs([0, ...places, str.length]).map(
  ([a, b]) => str.substring(a, b)
);

const breakWhere = (words, str) => breakAt(
  words.reduce((a, {offset, length}) => [...a, offset, offset + length], []),
  str
);

const createNodes = (errors, str) => {
  const sortedErrors = errors.slice(0).sort(({offset: o1}, {offset: o2}) => o1 - o2)
  return breakWhere(sortedErrors, str).map((s, i) => i % 2 == 0
    ? {data: s, type: 'text'}
    : {data: s, type: 'error', error: sortedErrors[(i - 1) / 2]}
  ).filter(({data}) => data.length > 0)
}


function makeDummyTranscriptChunks() {
  return [
    {data: 'Down there ', type: 'text'},
    {data: 'turquoise', type: 'error', error: {type: 'Grammar', offset: 11, length: 9, description: 'is a turquoise'}},
    {data: ' sea. Down there chartreuse vessel this ', type: 'text'},
    {data: 'are', type: 'error', error: {type: 'Grammar', offset: 60, length: 3, description: 'is'}},
    {data: ' bad grammar ', type: 'text'},
    {data: 'plese', type: 'error', error: {type: 'Spelling', offset: 77, length: 5, description: 'please'}},
    {data: ' fix me. ', type: 'text'},
    {data: 'thiere', type: 'error', error: {type: 'Spelling', offset: 91, length: 6, description: 'there'}},
    {data: ' is one vase.', type: 'text'},
  ];
}

