import React from "react";
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import '../../styles/cardstyles.css';

import toast from 'react-hot-toast';
import apiCall from "../utilities/auth/apiCall";
import { getSavedLessons } from "../utilities/auth/authApi";
import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import LessonCard from "../utilities/lessoncards/lessonCard";
import LoadingBasic from "../utilities/loadingbasic";

import savedLessonsSlice from "../../store/savedlessons";

import fadeInMainBody from "../../functions/fadeinmainbody";


function Saved() {

  // Define variables
  const dispatch = useDispatch();
  const savedLessonsLoaded = useSelector((state) => state.savedLessons.initialized);
  const savedLessons = useSelector((state) => state.savedLessons.lessons);


  // Page load actions
  useEffect(() => {

    fadeInMainBody();

    if( !savedLessonsLoaded ) {
      apiCall(getSavedLessons, {}).then((response) => {

        if( response.success ) {

          const payload = {
            initialized: true,
            lessons: response.data.savedLessons
          };

          // dispatch({ type: 'initializeSavedLessons', payload: payload });
          dispatch(savedLessonsSlice.actions.initializeSavedLessons(payload));

        } else {
          toast.error(response.error);
        }
      });
    }

  });


  // Screen components
  let loadingElement;
  if( !savedLessonsLoaded ) {
    loadingElement = <LoadingBasic />;
  }

  let blankState;
  let mainBodyClass = 'main-body-content lesson-cards';
  if( savedLessonsLoaded && !savedLessons.length ) {
    blankState = <p class='blank-state'>You have not saved any lessons yet. To save a lesson, click the save icon <span class='icon icon-saved is-24x24 image'></span> when viewing a lesson.</p>
    mainBodyClass = 'main-body-content';
  }


  return (

    <div class='wrapper gray-background'>

      <Nav />

      <div class='main-body invisible'>
        <HeaderMain title="Saved" />
        <div class={mainBodyClass}>

          <div class='lesson-cards-container'>
            {savedLessons.map((lesson, index) =>
              <LessonCard
                lesson={lesson}
                key={index}
                index={index}
                hideProgressBars={true}
              />
            )}
          </div>

          {blankState}
          {loadingElement}

        </div>
      </div>
      <BottomNav />
    </div>

  );
}

export default Saved;