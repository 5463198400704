import CatalogLesson from "./catalogLesson";


function CatalogSection(props) {

  // Define variables
  const title = props.title;
  const list = props.list;


  return (
    <div style={{marginTop: '120px'}} id={title}>
      <p>FLOW Speak Lesson Library</p>
      <h2 style={{marginBottom: '10px'}}>{title}</h2>
      <div class='flex-row catalog-lesson-container catalog-lesson-container-heading'>
        <span>ID</span>
        <span>Title</span>
        <span>Duration</span>
        <span>Level</span>
        <span>CEFR Level</span>
        <span>CEFR Sub Level</span>
        <span>GSE Level</span>
      </div>

      {list.map((lesson, index) =>
        <CatalogLesson lesson={lesson} key={`catalog-bite-${index}`} />
      )}

    </div>
  );
}

export default CatalogSection;