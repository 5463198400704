import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  courses: []
}

export const name = 'courses';

const coursesSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeCourses: (state, action) => {
      state.initialized = action.payload.initialized;
      state.courses = action.payload.courses;
    },
  },
});

export const { actions, reducer } = coursesSlice;
export default coursesSlice;



// // ----
// export function coursesReducer(state = initialSate, action) {

//   if( action.type === 'initializeCourses') {
//     return {
//       ...state,
//       initialized: action.payload.initialized,
//       courses: action.payload.courses
//     };
//   }

//   return state;
// }