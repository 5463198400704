import React from "react";
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import '../../styles/start.css';

import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import { getStripePackageIds } from "../utilities/stripe/stripeHelpers.js";
import StartLeftColumn from "./startLeftColumn";
import UnauthedStartHeader from "./unauthedStartHeader";
import OnboardingPackagePriceButton from "./onboardingPackagePriceButton";
import PromoCode from "../packages/promocode";
import PriceComparisonTable from "../utilities/priceComparisonTable";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";

import packageSlice from "../../store/package.js";


function WelcomeChooseMembership() {

  // Dispatch functions
  const dispatch = useDispatch();

  function setPackageId(packageId) {
    // dispatch({ type: 'setPackageId', payload: {packageId: packageId}});
    dispatch(packageSlice.actions.setPackageId({packageId: packageId}));
  }


  // Define variables
  const navigate = useNavigate();
  const pricingTier = useSelector((state) => state.profile.pricingTier);
  const stripePackageIds = getStripePackageIds(pricingTier);
  const packageId = useSelector((state) => state.package.packageId);
  const { slug } = useParams();

  let autoUpgradePath = '/welcome-mic-test';
  // let autoUpgradePath = '/welcome-choose-course';
  if( slug ) { autoUpgradePath = `/register/${slug}/complete` }

  let providedPromoCode = '';
  if( URLSearchParams ) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    providedPromoCode = urlParams.get('promo_code') || urlParams.get('promoCode');
  }


  // Page load actions
  useEffect(() => {

    // fadeInMainBody();

    // Specific tracking for onboarding steps
    const mixpanelParams = { 'registerType': slug ? 'orgRegister' : 'regularRegister' };
    Mixpanel.track('Onboarding: Welcome Choose Membership', mixpanelParams);

  }, []);


  // Define functions
  function selectPackage(packageId) {
    setPackageId(packageId);
  }

  function nextStep() {

    if( !packageId ) {
      alert('Please choose a membership');
      return;
    }

    if( packageId === 'community' ) {
      confirmCommunityMembership();
    } else {
      let path = '/welcome-purchase';
      if( slug ) { path = `/register/${slug}/purchase` }
      navigate(path);
    }
  }

  function confirmCommunityMembership() {
    if (window.confirm("Are you sure you don't want Premium?")) {
      navigate(autoUpgradePath);
    }
  }


  return (

    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        {/* <div class='main-body-content start welcome'> */}
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
              <div class='start-vertical-container'>

              {/* <StartHeader hideTagline={true} /> */}
              <UnauthedStartHeader
                hideTitle={true}
                subTitle={"Choose Your Membership"}
              />

              {/* <h2>Choose Your Membership</h2> */}

              <div class='choose-package-button-wrapper onboarding'>

                <OnboardingPackagePriceButton
                  packageId={'community'}
                  title={'Community Member'}
                  selectedPackage={packageId}
                  setSelectedPackage={selectPackage}
                />

                <OnboardingPackagePriceButton
                  packageId={stripePackageIds.monthlyPackageId.packageId}
                  title={'Monthly Subscription'}
                  duration={'month'}
                  price={stripePackageIds.monthlyPackageId.price}
                  commonName={'monthly'}
                  selectedPackage={packageId}
                  setSelectedPackage={selectPackage}
                />

                <OnboardingPackagePriceButton
                  packageId={stripePackageIds.sixMonthPackageId.packageId}
                  title={'6-Month Subscription'}
                  duration={'6-months'}
                  price={stripePackageIds.sixMonthPackageId.price}
                  discount={stripePackageIds.sixMonthPackageId.discount}
                  commonName={'sixmonth'}
                  selectedPackage={packageId}
                  setSelectedPackage={selectPackage}
                />

                <OnboardingPackagePriceButton
                  packageId={stripePackageIds.yearlyPackageId.packageId}
                  title={'Yearly Subscription'}
                  duration={'year'}
                  price={stripePackageIds.yearlyPackageId.price}
                  discount={stripePackageIds.yearlyPackageId.discount}
                  commonName={'yearly'}
                  selectedPackage={packageId}
                  setSelectedPackage={selectPackage}
                />

              </div>

              <PromoCode
                autoUpgradePath={autoUpgradePath}
                providedPromoCode={providedPromoCode}
              />

              <div class='flex-column' style={{marginTop: '15px'}}>
                <ButtonSubmitRound
                  value={'Continue'}
                  handleButtonClick={nextStep}
                />
              </div>

              <br />
              <PriceComparisonTable />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeChooseMembership;