import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';

import '../../styles/progress.css';

import { reportError } from "../../functions/sentry";
import { getLessons } from "../utilities/auth/authApi";
import ProgressHeader from "./progressHeader";
import BottomNav from "../utilities/bottomnav";
import ProgressSubNav from "./progresssubnav";
import DoughnutChart from "../utilities/graphs/doughnutchart";
import LoadingBasic from "../utilities/loadingbasic";
import LessonCardDetail from "../utilities/lessoncards/lessonCardDetail";

import progressLessonsSlice from "../../store/progressLessons";


function ProgressLessons() {

  // Dispatch functions
  const dispatch = useDispatch();

  function initializeProgressLessons(payload) {
    // dispatch({ type: 'initializeProgressLessons', payload: payload });
    dispatch(progressLessonsSlice.actions.initializeProgressLessons(payload));
  }

  function setProgressDisplayLessons(array) {
    // dispatch({ type: 'setProgressDisplayLessons', payload: array });
    dispatch(progressLessonsSlice.actions.setProgressDisplayLessons(array));
  }

  function setProgressPage(page) {
    // dispatch({ type: 'setProgressPage', payload: page });
    dispatch(progressLessonsSlice.actions.setProgressPage(page));
  }


  // Define variables
  const progressLessonsInitialized  = useSelector((state) => state.progressLessons.initialized);
  const allLessons = useSelector((state) => state.progressLessons.allLessons);
  const displayLessons = useSelector((state) => state.progressLessons.displayLessons);
  const page = useSelector((state) => state.progressLessons.page);
  const chartData = useSelector((state) => state.progressLessons.chartData);

  const profile = useSelector((state) => state.profile);
  const publicProfileId = profile.publicProfileId;
  const confidenceLevel = profile?.score ? `${profile.score.score} Confidence Level` : '';
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;

  const profileData = {
    avatarUrl: profile.avatarUrl,
    firstName: profile.firstName,
    flowScore: confidenceLevel,
    countryCode: profile.country,
  };


  // Page load actions
  useEffect(() => {

    if( publicProfileId && !progressLessonsInitialized) {

      setIsLoading(true);

      getLessons(publicProfileId).then((response) => {
        if( response?.data?.success ) {

          const allLessons = response.data.lessonsData.lessons;
          const displayLessons = paginate(allLessons, pageSize, page);
          const chartData = ( allLessons.length > 0 ) ? response.data.lessonsData.chartData : null;

          const payload = {
            allLessons: allLessons,
            displayLessons: displayLessons,
            chartData: chartData
          }

          initializeProgressLessons(payload);
          setIsLoading(false);
        }
      }).catch((error) => {
        reportError(error);
      });
    }

  }, [publicProfileId]);


  // Define functions
  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  function loadMoreLessons() {
    const nextPage = page + 1;
    const nextLessons = paginate(allLessons, pageSize, nextPage);

    const existingLessons = [...displayLessons];
    const newLessons = existingLessons.concat(nextLessons);
    setProgressDisplayLessons(newLessons);
    setProgressPage(nextPage);
  }


  // Screen components
  let loadingElement;
  if( isLoading ) {
    loadingElement = <LoadingBasic />;
  }

  let graph;
  if( chartData ) {
    graph = <DoughnutChart data={chartData} />;
  }

  let lessonCountSummaryElement;
  const lessonCount = allLessons.length;
  if( lessonCount > 0 ) {
    lessonCountSummaryElement= (
      <div class='lesson-count-summary'>
        <h2>{lessonCount}</h2>
        <p>Lessons<br />Completed</p>
      </div>
    );
  }

  let blankStateElement;
  if( progressLessonsInitialized && lessonCount === 0 ) {
    blankStateElement = (
      <div class='flex-column'>
        <div style={{margin: '60px', maxWidth: '280px', textAlign: 'center'}}>
          <h3>No lessons</h3>
          <p>You have not completed any lessons yet. Jump in and take your first lesson. You can do it!</p>
        </div>
      </div>
    );
  }

  let showMoreElement;
  const displayLessonCount = displayLessons.length;
  if( displayLessonCount > 0 && displayLessonCount < lessonCount ) {
    showMoreElement = (
      <div class='flex-column' style={{marginBottom: '60px'}} >
        <button
          class='button'
          onClick={() => loadMoreLessons()}
        >Show More</button>
      </div>
    );
  }


  return (
    <div class='flex-column wrapper progress-screen'>
      <div class='main-container'>

        <ProgressHeader
          profileData={profileData}
          isThisLearner={true}
        />

        <ProgressSubNav />

        <div class={`main-body with-sub-nav`}>
          <div class='main-body-content'>

            {loadingElement}
            {blankStateElement}

            <div class='flex-column'>
              <div class='lesson-graph-wrapper'>
                {lessonCountSummaryElement}
                {graph}
              </div>
            </div>

            <div class='flex-row lessons-list-wrapper'>
              {displayLessons.map((lesson, index) =>
                <LessonCardDetail
                  lesson={lesson}
                  index={index}
                  key={`progress-lesson-${index}`}
                />
              )}
            </div>

            {showMoreElement}

          </div>
        </div>
        <BottomNav />
      </div>
    </div>
  )
}

export default ProgressLessons;