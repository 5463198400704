import React from "react";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import '../../styles/course.css';

import CourseCard from "./coursecard";
import CoursePrepModal from "./courseprepmodal";

import { reportError } from "../../functions/sentry";
import { getCourses, startCourse, cancelCourse } from "../utilities/auth/authApi";
import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import LoadingBasic from "../utilities/loadingbasic";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';

import fadeInMainBody from "../../functions/fadeinmainbody";

import coursesSlice from "../../store/courses";


function Courses() {

  // See if profile & this week data has already been loaded
  const coursesLoaded = useSelector((state) => state.courses.initialized);
  const courses = useSelector((state) => state.courses.courses);
  const hasPremiumSubscription = useSelector((state) => state.profile.hasPremiumSubscription);

  const [coursePrepStatus, setCoursePrepStatus] = useState('inactive');
  const [coursePrepErrors, setCoursePrepErrors] = useState([]);
  const [loading, setLoading] = useState((coursesLoaded ? null : <LoadingBasic />));

  const dispatch = useDispatch();


  useEffect(() => {

    fadeInMainBody();

    if( !coursesLoaded ) {
      getCourses().then((response) => {
        if( response.data ) {
          response.data.initialized = true;

          // Update the redux store
          // dispatch({ type: 'initializeCourses', payload: response.data });
          dispatch(coursesSlice.actions.initializeCourses(response.data));

          setLoading(false);
        }
      });
    }

  });


  function handleStartCourse(id) {

    Mixpanel.track('Event: Clicked to start course');

    const payload = {id: id};
    setCoursePrepStatus('waiting');

    startCourse(payload).then((response) => {

      if( response.data ) {

        if( response.data.success ) {
          setTimeout(() => setCoursePrepStatus('ready'), 1500);
          Mixpanel.track('Event: Course Started');

        } else {
          setCoursePrepErrors(response.data.error);
          setTimeout(() => setCoursePrepStatus('error'), 1500);
          Mixpanel.track('Event: Course Start Error 1', {'Error': JSON.stringify(response.data.error)});
        }

      } else {
        // Handle
        Mixpanel.track('Event: Course Start Error 2', {'Error': JSON.stringify(response)});
      }
    }).catch((error) => {
      reportError(error);
    });

  }


  function handleCancelCourse(course_id) {

    Mixpanel.track('Event: Clicked to cancel course');

    const payload = {id: course_id};
    setCoursePrepStatus('waitingCancel');

    cancelCourse(payload).then((response) => {

      if( response.data ) {

        if( response.data.success ) {
          setTimeout(() => setCoursePrepStatus('cancelled'), 1500);
          Mixpanel.track('Event: Course Cancelled');

        } else {
          setCoursePrepErrors(response.data.error);
          setTimeout(() => setCoursePrepStatus('error'), 1500);
          Mixpanel.track('Event: Course Cancel Error 1', {'Error': JSON.stringify(response.data.error)});
        }

      } else {
        // Handle
        Mixpanel.track('Event: Course Cancel Error 2', {'Error': JSON.stringify(response)});
      }
    }).catch((error) => {
      reportError(error);
    });

  }


  function closeModal() {
    setCoursePrepErrors([]);
    setCoursePrepStatus('inactive')
  }


  return (

    <div class='wrapper gray-background'>

      <CoursePrepModal
        status={coursePrepStatus}
        coursePrepErrors={coursePrepErrors}
        closeModal={closeModal}
      />

      <Nav />

      <div class='main-body invisible'>
        <HeaderMain title="Courses" />
        <div class='main-body-content courses'>

          <p>
            {/* Courses provide a guided learning path to help you reach your goals. &#127775;
            <br /><br />
            When you start a course, your Dashboard will list all the Lessons grouped by week, so you have a curated list of Lessons to complete each week. */}
            Follow a guided plan to reach your goals. With each course, you <strong>build a specific skill</strong> and <strong>jumpstart your speaking ability</strong>. 🌟
            <br /><br />
            After you select the course, weekly lessons will be arranged on your Dashboard.
          </p>

          <div class='course-list-wrapper'>
            {courses.map((course, index) =>
              <CourseCard
                course={course}
                hasPremiumSubscription={hasPremiumSubscription}
                handleStartCourse={handleStartCourse}
                key={index}
                handleCancelCourse={handleCancelCourse}
              />
            )}
          </div>

          {loading}

        </div>
      </div>
      <BottomNav />
    </div>

  );
}

export default Courses;