import { Link } from "react-router-dom";
import NextLesson from "./nextLesson";
// import LessonCompleteModalCourseScore from "./lessonCompleteModalCourseScore";
import ShareResponsesButton from "./shareResponsesButton";
import FooterStepElement from "./footerStepElement";


function LessonCompleteModal(props) {

  // Define functions
  // const closeModal = props.closeModal;
  const reset = props.reset;
  const data = props.data;
  const score = data.score;
  const pointsEarned = data.pointsEarned;
  const nextLesson = data.nextLesson;
  const courseData = data.courseData;
  const exerciseId = data.exerciseId
  const canShareResponses = data.canShareResponses;
  const isIelts = data.lessonIsIelts;
  const ieltsScore = data.ieltsScore;
  const stepData = data.stepData;


  // Screen components
  let headerElement;
  let bodyElement;
  let bodyScoreElement;
  let bodyPointsElement;
  let shareElement;
  let footerElement;
  let footerNextLessonElement;
  let footerStepElement
  let footerBackText;

  // Header
  headerElement = (
    <div class='modal-header'>
      <p>Lesson Complete</p>
      <h2>Great Job!</h2>
      <div class='border-bottom'>
        <span></span>
        <span></span><span></span>
        <span></span><span></span><span></span><span></span>
        <span></span><span></span><span></span><span></span>
        <span></span><span></span><span></span><span></span>
        <span></span><span></span><span></span><span></span>
        <span></span><span></span><span></span><span></span>
      </div>
    </div>
  );


  // Body
  if( score ) {
    bodyScoreElement = (
      <div class='modal-body-score-container'>
        <h1>{score}%</h1>
        <p>Lesson Score</p>
      </div>
    );
  }

  if( isIelts && ieltsScore ) {
    bodyScoreElement = (
      <div class='modal-body-score-container'>
        <h1>{ieltsScore}</h1>
        <p>Lesson Score</p>
      </div>
    );
  }

  if( pointsEarned ) {
    bodyPointsElement = (
      <div class='modal-body-points-container'>
        <p class='you-earned'>You Earned</p>
        <div class='earned-banner'>
          <p><strong>{pointsEarned}</strong></p>
          <p>&nbsp;Confidence Level Points</p>
        </div>
      </div>
    );
  }


  bodyElement = (
    <div class='modal-body-container'>
      {bodyScoreElement}
      {bodyPointsElement}
    </div>
  );


  // Share
  if( canShareResponses ) {
    shareElement = <ShareResponsesButton exerciseId={exerciseId} />;
  }


  // Footer
  if( nextLesson ) {

    footerBackText = 'Or return the Dashboard';

    footerNextLessonElement = (
      <NextLesson
        nextLesson={nextLesson}
        functionToCallAfterNavigate={reset}
      />
    );

  } else {
    footerBackText = 'Back to the Dashboard';

    if( stepData ) {
      footerStepElement = <FooterStepElement stepData={stepData} />;
    }
  }

  footerElement = (
    <div class='modal-footer'>
      {footerNextLessonElement}
      {footerStepElement}
      <Link
        to={'/'}
        class='footer-back-link'
        title={'Return to the Dashboard'}
        onClick={reset}
      >{footerBackText}</Link>
    </div>
  );


  // Course
  // let courseElement;
  if( courseData ) {

    // courseElement = (
    //   <LessonCompleteModalCourseScore courseData={courseData} />
    // );

    // Override the footer element to force reload when going back to the dashboard
    // by adding the reloadDocument attribute
    footerElement = (
      <div class='modal-footer'>
        {footerNextLessonElement}
        {footerStepElement}
        <Link
          to={'/'}
          class='footer-back-link'
          title={'Return to the Dashboard'}
          onClick={reset}
          reloadDocument
        >{footerBackText}</Link>
      </div>
    );
  }


  return (
    <div class='window-modal-wrapper'>
      <div class='window-modal lesson-complete-modal'>
        {headerElement}
        {bodyElement}
        {shareElement}
        {/* {courseElement} */}
        {footerElement}
      </div>
    </div>
  );
}

export default LessonCompleteModal;