import React from "react";
import { useState } from 'react';
import { useDispatch} from "react-redux";

import axios from 'axios';
import Avatar from 'react-avatar-edit'

import alertMessage from '../utilities/alertMessage';
import Modal from "../utilities/modal";
import { getToken } from "../utilities/auth/tokenHelpers";
import VectorIcon from "./vectorIcon";
import AvatarImageUploadModal from "./avatarImageUploadModal";

import profileSlice from "../../store/profile";


function AvatarImageUpload() {

  // Define Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    dispatch(profileSlice.actions.updateField({field, value}));
  }


  // Define variables
  const [showModal, setShowModal] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [imageData, setImageData] = useState(null);


  // Define functions
  function openImagePicker() {
    setShowModal(true);
  }

  function saveData() {
    if( !imageData ) {
      alertMessage('Please choose a file first', 'error');
      return;
    }
    setIsSaving(true);
    handleImageData(imageData);
  }

  function onCrop(data) {
    setImageData(data);
  }

  function onBeforeFileLoad(element) {
    if(element.target.files[0].size > 3000000){
      alertMessage('Please choose a file that is less than 3MB', 'error');
      element.target.value = "";
    };
  }

  function handleImageData(base64) {
    fetch(base64).then(res => res.blob())
    .then(blob => {

      const formData = new FormData();
      formData.append(`user[avatar]`, blob, 'avatar');

      const config = {
        method: 'post',
        url: 'user/update_personal_information',
        headers: { authorization: `Bearer ${getToken()}`, "Content-Type": "multipart/form-data" },
        data : formData
      };

      // Send the data to the server
      return axios(config)
      .then(function (response) {
        if( response.data.success ) {

          updateField('avatarUrl', response.data.avatarUrl);

          alertMessage('Profile successfully updated');
        } else {
          alertMessage(response.data.error, 'error');
        }

      })
      .catch(function (error) {
        alertMessage('Sorry, there was an error', 'error');
      });

    }).finally(() => {
      setIsSaving(false);
    });
  }


  // Screen components
  let uploadModalElement;
  if( showModal ) {
    const body = (
      <AvatarImageUploadModal
        onBeforeFileLoad={onBeforeFileLoad}
        isSaving={isSaving}
        saveData={saveData}
        onCrop={onCrop}
        updateField={updateField}
      />
    );

    uploadModalElement = (
      <Modal
        closeModal={() => setShowModal(false)}
        title={'Add Your Photo'}
        centerTitle={true}
        body={body}
        closeIcon={true}
      />
    );
  }


  return (
    <div>
      {uploadModalElement}

      <button
        class='clean-button pointer flex-row header-avatar-upload-container'
        onClick={() => openImagePicker()}
      >
        <p>Add photo</p>
        <div class='flex-column header-avatar-upload-icon'>
          <VectorIcon icon='personAdd' size={'20px'} />
        </div>
      </button>

    </div>
  );
}

export default AvatarImageUpload;