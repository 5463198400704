import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

import { Mixpanel } from '../analytics/mixpanelHelpers';
import grammarCheck from '../../../functions/grammarCheck';
import ScoreDetailsTooltipModal from './scoreDetailsTooltipModal';
import ScoreDonut from './scoredonut';
import LoadingGrammar from "../loadingGrammar";
import WordScoreContent from './wordScoreContent';

import lessonSlice from '../../../store/lesson';


function ScoreDetailsModal(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function updateGrammarErrors(key, value) {
    // dispatch({ type: 'updateGrammarErrors', payload: {key: key, value: value}});
    dispatch(lessonSlice.actions.updateGrammarErrors({key: key, value: value}));
  }

  // Define variables
  const hideSeeDetails = props.hideSeeDetails;
  const hideScoreDetailsModal = props.hideScoreDetailsModal;
  const ieltsCourse = props.ieltsCourse;
  const responseData = props.responseData;
  const transcript = responseData.transcript;
  const wordScore = responseData.wordScore;
  const questionId = props.questionId;
  const grammarErrors = useSelector((state) => state.lesson.grammarErrors[questionId]);

  const [grammarBreakdown, setGrammarBreakdown] = useState(<LoadingGrammar />);
  const [scoreDetailsTooltipModal, setScoreDetailsTooltipModal] = useState(null);
  const [activeScoreTab, setActiveScoreTab] = useState('pronunciation');

  let scoreDetailsClass = 'score-details';
  if( hideSeeDetails ) {
    scoreDetailsClass = 'score-details no-access';
  }


  // Seemed that responseData wasn't always populated,
  // so just define the variables to start
  // let overallScore = responseData.overall.word;
  // let overallScorePercent = responseData.overall.percent;
  let overallScore;
  let overallScorePercent;



  // Page load actions
  useEffect(() => {

    // TO DO - check if the grammar check is requested
    if( !hideSeeDetails ) {
      Mixpanel.track('Event: Show Score Details');
    } else {
      Mixpanel.track('Event: Show Score Details - Obscured');
    }

  }, [responseData]);



  // Define functions
  function showScoreDetailsTooltipModal(event, content) {
    setScoreDetailsTooltipModal(
      <ScoreDetailsTooltipModal
        closeScoreDetailsTooltipModal={closeScoreDetailsTooltipModal}
        event={event}
        content={content}
      />
    );
  }

  function closeScoreDetailsTooltipModal() {
    setScoreDetailsTooltipModal(null);
  }


  function runGrammarCheck() {

    let needToSetGrammarErrors = !grammarErrors;
    let transcriptChunks;

    grammarCheck(transcript, grammarErrors).then((response) => {

      transcriptChunks = response || [];
      const transcriptArray = [];
      let errorsFound = false;

      transcriptChunks.map((object, index) => {

        const objectType = object.type;
        const objectData = object.data;
        let data;

        if( objectType === 'text' ) {
          data = objectData;
        // } else if ( objectType === 'error' ) {
        // Use Else b/c we could get a spelling error too
        } else {

          errorsFound = true;

          let errorText = object.error.description;
          if( errorText === '' ) {
            errorText = `Remove "${object.data}"`;
          }

          const text = (
            <div>
              <p><strong>{object.error.type} error found</strong></p>
              <hr />
              <p>Possible Replacement:</p>
              <span class='ml-3 mt-2'>
                {errorText}
              </span>
            </div>
          );

          data = (
            <button
              type='button'
              key={index}
              class='clean-button grammar-error'
              title={'Tap to see details'}
              onClick={(event) => showScoreDetailsTooltipModal(event, text)}
            >{objectData}</button>
          );
        }

        transcriptArray.push(data);
      });

      // Push the data into redux for reuse if needed
      if( needToSetGrammarErrors ) { updateGrammarErrors(questionId, transcriptChunks) }


      let innerContent = <p><strong>Good job</strong>, we didn't find any grammar errors</p>;
      if( errorsFound ) {
        innerContent = transcriptArray;
      }

      const grammarBreakdownElement = (
        <div class='grammar-detail-breakdown'>
          {innerContent}
        </div>
      );

      const timeout = needToSetGrammarErrors ? 1000 : 1;
      setTimeout(() => {
        setGrammarBreakdown(grammarBreakdownElement);
      }, timeout);

    });

  }


  // Screen components
  let bodyContent;
  if( ieltsCourse && responseData.ieltsScores ) {

    const ieltsOverallScore = responseData.ieltsScores.overall;
    const ieltsOverallPercent = ieltsOverallScore;

    overallScore = ieltsOverallScore;
    overallScorePercent = ieltsOverallScore;

    const ieltsFluencyAndCoherenceScore = responseData.ieltsScores.fluencyAndCoherence;
    const ieltsFluencyAndCoherencePercent = ieltsFluencyAndCoherenceScore;

    const wordCountMessage = responseData.ieltsScores.wordCountMessage;

    const ieltsVocabularyScore = responseData.ieltsScores.vocabulary;
    const ieltsVocabularyPercent = ieltsVocabularyScore;

    const ieltsGrammarScore = responseData.ieltsScores.grammar;
    const ieltsGrammarPercent = ieltsGrammarScore;

    const ieltsPronunciationScore = responseData.ieltsScores.pronunciation;
    const ieltsPronunciationPercent = ieltsPronunciationScore;

    bodyContent = (
      <div class='score-detail-breakdown'>
        <div class='score-detail-breakdown-item'>
          <span><b>Overall</b></span>
          <span class='score-detail-breakdown-score'>
            <span>{ieltsOverallScore}</span>
            <ScoreDonut scorePercent={ieltsOverallPercent} isIelts={true} />
          </span>
        </div>
        <div class='score-detail-breakdown-item'>
          <span>Fluency and Coherence</span>
          <span class='score-detail-breakdown-score'>
            <span>{ieltsFluencyAndCoherenceScore}</span>
            <ScoreDonut scorePercent={ieltsFluencyAndCoherencePercent} isIelts={true} />
          </span>
        </div>
        <div class='score-detail-breakdown-item'>
          <span>
            Lexical Resource &nbsp;
            {wordCountMessage}
          </span>
          <span class='score-detail-breakdown-score'>
            <span>{ieltsVocabularyScore}</span>
            <ScoreDonut scorePercent={ieltsVocabularyPercent} isIelts={true} />
          </span>
        </div>
        <div class='score-detail-breakdown-item'>
          <span>Grammatical Range and Accuracy</span>
          <span class='score-detail-breakdown-score'>
            <span>{ieltsGrammarScore}</span>
            <ScoreDonut scorePercent={ieltsGrammarPercent} isIelts={true} />
          </span>
        </div>
        <div class='score-detail-breakdown-item'>
          <span>Pronunciation</span>
          <span class='score-detail-breakdown-score'>
            <span>{ieltsPronunciationScore}</span>
            <ScoreDonut scorePercent={ieltsPronunciationPercent} isIelts={true} />
          </span>
        </div>

      </div>
    );

  } else {

    overallScore = responseData.overall.word;
    overallScorePercent = responseData.overall.percent;

    const pronunciationScore = responseData.pronunciation.percent;
    const cadenceScore = responseData.cadence.percent;
    const fluencyScore = responseData.fluency.percent;

    const speechRate = responseData.cadence.speechRate;
    const wordCount = responseData.wordCount || 0;

    let speechRateElement;
    if( speechRate && wordCount > 2 ) {
      if( speechRate < 2.5 ) {
        speechRateElement = <span class='error'>( speed up )</span>;
      } else if( speechRate > 6 ) {
        speechRateElement = <span class='error'>( slow down )</span>;
      }
    }

    bodyContent = (
      <div class='score-detail-breakdown'>
        <div class='score-detail-breakdown-item'>
          <span><b>Overall</b></span>
          <span class='score-detail-breakdown-score'>
            <span>{overallScore}</span>
            <ScoreDonut
              overallScorePercent={overallScorePercent}
              overallScoreWord={overallScore}
            />
          </span>
        </div>
        <div class='score-detail-breakdown-item'>
          <span>Pronunciation</span>
          <span class='score-detail-breakdown-score'>
            <span>{pronunciationScore}%</span>
            <ScoreDonut
              scorePercent={pronunciationScore}
            />
          </span>
        </div>
        <div class='score-detail-breakdown-item'>
          <span>Speed {speechRateElement}</span>
          <span class='score-detail-breakdown-score'>
            <span>{cadenceScore}%</span>
            <ScoreDonut
              scorePercent={cadenceScore}
            />
          </span>
        </div>
        <div class='score-detail-breakdown-item'>
          <span>Fluency</span>
          <span class='score-detail-breakdown-score'>
            <span>{fluencyScore}%</span>
            <ScoreDonut
              scorePercent={fluencyScore}
            />
          </span>
        </div>
      </div>
    );

  }


  let premiumCta;
  if( hideSeeDetails ){
    premiumCta = (
      <div class='word-detail-premium-cta'>
        <div class='mt-6'>
          Advanced speech scoring
          <br />
          is a premium feature.
          <br /><br />
          <Link to='/packages' class='button' title='View Premium Membership package'>Upgrade to a Premium Membership.</Link>
        </div>
      </div>
    );
  }

  let tabContent;
  if( activeScoreTab === 'pronunciation' ) {
    tabContent = (
      <WordScoreContent
        wordScore={wordScore}
        showScoreDetailsTooltipModal={showScoreDetailsTooltipModal}
      />
    );
  } else if( activeScoreTab === 'grammar' ) {
    tabContent = grammarBreakdown;
  }


  function handleGrammarTabClick() {
    runGrammarCheck();
    setActiveScoreTab('grammar');

    Mixpanel.track('Event: View Grammar Check');
  }


  return(
    <div class={scoreDetailsClass}>
      {scoreDetailsTooltipModal}
      {premiumCta}
      <div class="arrow-up"></div>
      <div class='score-details-header'>
        <span>&nbsp;</span>
        <p><b>Score Details</b></p>
        <button class='icon icon-close' title='Close' onClick={hideScoreDetailsModal}></button>
      </div>

      {bodyContent}

      <div class='score-tabs-container'>
        <div class='flex-row score-tabs'>
          <button
            class={`clean-button score-tab ${activeScoreTab === 'pronunciation' ? 'active' : null}`}
            onClick={() => setActiveScoreTab('pronunciation')}
          >Pronunciation</button>
          <button
            class={`clean-button score-tab ${activeScoreTab === 'grammar' ? 'active' : null}`}
            onClick={() => handleGrammarTabClick()}
          >Grammar</button>
        </div>
        {tabContent}
      </div>

    </div>
  );
}

export default ScoreDetailsModal;