import { useDispatch } from "react-redux";
import { useState } from 'react';
import apiCall from '../auth/apiCall';
import { retakeBlock } from "../auth/authApi";
import alertMessage from "../alertMessage";

import dashboardSlice from "../../../store/dashboard";


function StatsRetakeLevels(props) {

  // Dispatch
  const dispatch = useDispatch();

  function clearDashboardData() {
    // dispatch({ type: 'clearDashboardData' });
    dispatch(dashboardSlice.actions.clearDashboardData());
  }


  // Define variables
  const [disabled, setDisabled] = useState(false);
  const retakeLevels = props.retakeLevels;


  // Define functions
  function confirmRetakeLevel(id) {
    if( !id ) { return }

    if (window.confirm('Ready to take this Level? Your previous progress is saved, all you have to do is complete the remaining lessons.')) {
      retakeLevel(id);
    }
  }

  function retakeLevel(id) {
    setDisabled(true);

    apiCall(retakeBlock, {id: id}).then((response) => {
      if( response.success ) {
        clearDashboardData();
        alertMessage("Good job - let's get this Level done! Your previous lessons in this Level are saved. Go to the Home screen to take the lessons.");
      } else {
        alertMessage(response.error, 'error');
        setDisabled(true);
      }
    });
  }


  return (
    <div class='flex-column' style={{marginTop: '15px', marginBottom: '15px'}}>
      <div style={{maxWidth: '500px'}}>
        <p style={{marginBottom: '15px'}}>Get All Your Badges</p>
        <p>You have remaining lessons in some Levels.</p>
        <p style={{marginBottom: '15px'}}>Complete all the lessons to earn your badge.</p>
        <p style={{marginBottom: '15px'}}>Review the list:</p>
        <select
          onChange={(event) => confirmRetakeLevel(event.target.value)}
          class='select'
          disabled={disabled}
          style={{width: '250px'}}
        >
          <option value=''>Select a Level</option>
          {retakeLevels?.map((level, index) =>
            <option
              key={`retake-level-${index}`}
              value={level.id}
            >{level.pathway} Level {level.level}</option>
          )}
        </select>
      </div>
    </div>
  );
}

export default StatsRetakeLevels;