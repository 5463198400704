import React from "react";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import StepChoosePathway from "./stepChoosePathway";
import StepShowLevel from "./stepShowLevel";
import StepShowBadge from "./stepShowBadge";
import StepShowCertificate from "./stepShowCertificate";
import StepCourseCompleteShowScore from "./stepCourseCompleteShowScore";

import dashboardSlice from "../../../store/dashboard";


function StepRouter(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function clearDashboardData() {
    // dispatch({ type: 'clearDashboardData' });
    dispatch(dashboardSlice.actions.clearDashboardData());
  }

  // Define variables
  const stepRef = useRef(null);
  const stepData = props.stepData;
  const step = stepData.step;


  // Component load actions
  useEffect(() => {
    setTimeout(() => {
      if( stepRef && stepRef.current ) {
        stepRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end"
        })
      }
    }, 1000);
  }, []);


  // Screen components
  let stepElement;
  if( step === 0 ) {
    stepElement = <StepChoosePathway />;
  } else if( step === 1 ) {
    stepElement = <StepShowLevel stepData={stepData} clearDashboardData={clearDashboardData} />;
  } else if( step === 4 ) {
    stepElement = <StepShowBadge stepData={stepData} clearDashboardData={clearDashboardData} />;
  } else if( step === 5 ) {
    stepElement = <StepShowCertificate stepData={stepData} clearDashboardData={clearDashboardData} />;
  } else if( step === 'course_complete_show_score' ) {
    stepElement = <StepCourseCompleteShowScore stepData={stepData} clearDashboardData={clearDashboardData} />;
  }


  return(
    <div ref={stepRef}>
      {stepElement}
    </div>
  );
}

export default StepRouter;