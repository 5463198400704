import { useRef } from "react";
import VectorIcon from "../../utilities/vectorIcon";
import PlayAudioIcon from "../../utilities/playaudioicon";
import AsessScoreDonut from "./assessScoreDonut";
import AsessScoreFeedback from "./assessScoreFeedback";


function ScoreDetailCard(props) {

  // Define variables
  const cardRef = useRef(null);
  const index = props.index;
  const expandResponse = props.expandResponse;
  const showResponse = props.showResponse;
  const response = props.response;
  const title = response.title;
  const transcript = response.transcript;
  const audioUrl = response.audioUrl;
  const didNotRecord = response.didNotRecord;

  const pronunciation = response.pronunciation;
  const grammar = response.grammar;
  const vocabulary = response.vocabulary;
  const fluency = response.fluency;
  const relevance = response.relevance;


  // Screen components
  let arrowElement = <VectorIcon icon='chevronUp' size={'20px'} />;
  if( showResponse ) { arrowElement = <VectorIcon icon='chevronDown' size={'20px'} /> }

  let fluencyElement;
  let relevanceElement;
  let pronunciationElement;
  let grammarElement;
  let vocabularyElement;

  if( fluency.score ) { fluencyElement = <AsessScoreDonut score={fluency} title={'Fluency'} /> }
  if( relevance.score ) { relevanceElement = <AsessScoreDonut score={relevance} title={'Relevance'} /> }
  if( pronunciation.score ) { pronunciationElement = <AsessScoreDonut score={pronunciation} title={'Pronunciation'} /> }
  if( grammar.score ) { grammarElement = <AsessScoreDonut score={grammar} title={'Grammar'} /> }
  if( vocabulary.score ) { vocabularyElement = <AsessScoreDonut score={vocabulary} title={'Vocabulary'} /> }


  let fluencyFeedbackElement;
  let relevanceFeedbackElement;
  let grammarFeedbackElement;
  let vocabularyFeedbackElement;

  if( fluency.feedback ) { fluencyFeedbackElement = <AsessScoreFeedback feedback={fluency.feedback} title={'Fluency'} /> }
  if( relevance.feedback ) { relevanceFeedbackElement = <AsessScoreFeedback feedback={relevance.feedback} title={'Relevance'} /> }
  if( grammar.feedback ) { grammarFeedbackElement = <AsessScoreFeedback feedback={grammar.feedback} title={'Grammar'} /> }
  if( vocabulary.feedback ) { vocabularyFeedbackElement = <AsessScoreFeedback feedback={vocabulary.feedback} title={'Vocabulary'} /> }

  let assessResponseFeedbackElement;
  if( fluency.feedback || relevance.feedback || grammar.feedback || vocabulary.feedback ) {
    assessResponseFeedbackElement = (
      <div class='assess-response-feedback'>
        {fluencyFeedbackElement}
        {relevanceFeedbackElement}
        {grammarFeedbackElement}
        {vocabularyFeedbackElement}
      </div>
    );
  }


  let bodyElement;
  if( didNotRecord ) {
    bodyElement = (
      <div class='assess-response-container' style={showResponse ? {display: 'block'} : null}>
        <div style={{padding: '30px 15px 30px 15px'}}>
          <p>You did not record a response for this question.</p>
       </div>
      </div>
    );
  } else {
    bodyElement = (
      <div class='assess-response-container' style={showResponse ? {display: 'block'} : null}>

        <div class='flex-row assess-response-header'>
          <p class='assess-score-p'>{transcript}</p>
          <PlayAudioIcon audioUrl={audioUrl} width={'35px'} fullDark={true} />
        </div>

        <div class='flex-column assess-response-scores'>
          <div class='flex-row assess-response-scores-row'>
            {fluencyElement}
            {relevanceElement}
          </div>
          <div class='flex-row assess-response-scores-row'>
            {pronunciationElement}
            {grammarElement}
            {vocabularyElement}
          </div>
        </div>

        {assessResponseFeedbackElement}

      </div>
    );
  }



  return (
    <div class='assess-response-wrapper' ref={cardRef}>

      <button
        type='button'
        title='Tap to expand'
        class='clean-button flex-row assess-response-button'
        style={{padding: '5px 15px'}}
        onClick={() => expandResponse(index, cardRef)}
      >
        <h4>{title}</h4>
        {arrowElement}
      </button>


      {bodyElement}
    </div>
  );
}

export default ScoreDetailCard;