import React from "react";
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import '../../styles/notifications.css';

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import Notification from "./notification";

import notificationsSlice from "../../store/notifications";

import fadeInMainBody from "../../functions/fadeinmainbody";


function Notifications () {

  // Note, notifications are already being called in the Header Main component,
  // so we don't need to re-call it here since the Header Main component
  // is on all pages, including this one

  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.notifications.notifications);

  // Wait 2.5 seconds then turn off the new notification indicator
  setTimeout(() =>
    // dispatch({ type: 'setNewNotifications', payload: {newNotifications: false} }),
    dispatch(notificationsSlice.actions.setNewNotifications({newNotifications: false})),
  2500);

  let blankState;
  if( notifications.length === 0 ){
    blankState = <p>&#128075; All clear. There are no new notifications.</p>
  }

  useEffect(() => {
    fadeInMainBody();
  });


  return (
    <div class='wrapper gray-background'>

      <Nav />

      <div class='main-body invisible'>
        <HeaderMain title="Notifications" />
        <div class='main-body-content notifications'>

          {notifications.map((notification, index) =>
            <Notification notification={notification} index={index} key={`notification-${index}`} />
          )}

          {blankState}

          <div class='mt-6'>
            <p>
              <strong>Did you know?</strong>
              <br />
              You can add FLOW to your phone's home screen <i>so it's easier to practice every day.</i>
              &nbsp;<a href='https://www.flowspeak.io/faq/how-do-i-save-flow-to-my-phones-home-screen' target='_blank' rel="noreferrer">Learn how.</a>
            </p>
          </div>

        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default Notifications;