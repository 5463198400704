// import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import profileSlice from '../../store/profile';


function InfoCard(props) {

  // Define Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    // dispatch({ type: 'updateField', payload: {field, value} });
    dispatch(profileSlice.actions.updateField({field, value}));
  }


  // Define variables
  const navigate = useNavigate();
  const data = props.data;
  const title = data.title;
  const body = data.body;
  const pathwayBody = data.pathwayBody;
  const topImageUrl = data.topImageUrl;
  const linkToText = data.linkToText;
  const linkToScreen = data.linkToScreen; // courses, lesson, bite, listening, post, explore, membership, profile, progress
  const linkToId = data.linkToId;


  // Define functions
  function dismiss() {
    // Update redux
    updateField('infoCard', null);
  }

  function handlePress() {

    let path;

    switch(linkToScreen) {
      case 'courses':
        path = '/courses';
        break;
      case 'lesson':
        path = `/lessons/${linkToId}`;
        break;
      case 'bite':
        path = `/bites/${linkToId}`;
        break;
      case 'listening':
        path = `/listening/${linkToId}`;
        break;
      case 'aitalk':
        path = `/aitalks/${linkToId}`;
        break;
      case 'booster':
        path = `/booster/${linkToId}`;
        break;
      case 'post':
        path = `/community/posts/${linkToId}/comments`;
        break;
      case 'explore':
        path = `/explore`;
        break;
      case 'membership':
        path = `/packages`;
        break;
      case 'profile':
        path = `/profile`;
        break;
      case 'progress':
        path = `/progress`;
        break;
      case 'pathwaySelect':
        path = '/welcome-choose-pathway';
      default:

    }

    if( path ) {
      navigate(path);
    }

  }


  // Screen components
  let titleElement;
  if( title ) {
    titleElement = <h3>{title}</h3>;
  }

  let topImageElement;
  if( topImageUrl ) {
    topImageElement = <img src={topImageUrl} />;
  }

  let bodyElement;
  if( body ) {
    bodyElement = <p>{body}</p>;
  }

  let actionElement;
  if( linkToScreen && linkToText ) {

    actionElement = (
      <div onClick={() => handlePress()} class='action-button has-pointer'>
        {linkToText}
        <div class='icon icon-chevron-right-light'></div>
      </div>
    );
  }

  let pathwayBodyElement;
  if( pathwayBody ) {
    pathwayBodyElement = <p>{pathwayBody}</p>;
    //  (
    //   <div>
    //     <p>{pathwayBody}</p>
    //     {/* <br />
    //     <Link to='/welcome-choose-pathway' title='Choose your Pathway'>Choose your Pathway</Link> */}
    //   </div>
    // );
  }


  return(
    <div class='flex-column' style={{marginTop: '15px'}}>
      <div class='lesson-card info-card'>
        <div class='header-block'>
          <div class='icon'></div>
          <div class='flex-column'>
            {topImageElement}
            {titleElement}
          </div>
          <div class='icon icon-close has-pointer' tabIndex='0' onClick={() => dismiss()}></div>
        </div>
        {bodyElement}
        {pathwayBodyElement}
        {actionElement}
      </div>
    </div>
  );
}

export default InfoCard;