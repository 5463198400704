import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import apiCall from "../utilities/auth/apiCall";
import { getMoreBonusLessons } from "../utilities/auth/authApi";

import LessonCardSmall from "../utilities/lessoncards/lessonCardSmall";
import BonusLessonLocked from "../utilities/lessoncards/bonusLessonLocked";

import dashboardSlice from "../../store/dashboard";


function BonusLessons(props) {


  // Dispatch
  const dispatch = useDispatch();

  function clearDashboardData() {
    // dispatch({ type: 'clearDashboardData' });
    dispatch(dashboardSlice.actions.clearDashboardData());
  }

  // Define variables
  const [loading, setLoading] = useState(false);
  const hasPremiumSubscription = useSelector((state) => state.profile.hasPremiumSubscription);
  const isThisWeek = props.isThisWeek;
  const bonusLessons = props.bonusLessons || [];
  const weekIndex = props.weekIndex;


  // Define functions
  function loadMoreBonusLessons() {
    setLoading(true);

    apiCall(getMoreBonusLessons, {weekIndex: weekIndex}).then((response) => {
      if( response.success ) {
        setTimeout(() => clearDashboardData(), 1000);
      }
    }).finally(() => {
      setTimeout(() => setLoading(false), 3000);
    });
  }


  // Screen components
  let bonusText = <p>Finish your core lessons to unlock bonus lessons</p>;
  if( !hasPremiumSubscription ) {
    bonusText = (
      <Link to='/packages' title='Upgrade to Premium'>
        <p>Upgrade to Premium to unlock bonus lessons</p>
      </Link>
    );
  }

  let bonusLessonsElement;
  if( bonusLessons.length > 0 ) {

    bonusText = <p>Want more practice? Try out these lessons.</p>;

    let addMoreButton;
    if( isThisWeek && bonusLessons.length < 8 ) {

      if( loading ) {
        addMoreButton = <p class='add-more-bonus-lessons-button'>Loading ...</p>;
      } else {
        addMoreButton = (
          <button
            class='clean-button pointer add-more-bonus-lessons-button'
            type='button'
            onClick={() => loadMoreBonusLessons()}
            disabled={loading}
          >
            Add more bonus lessons
          </button>
        );
      }
    }


    bonusLessonsElement = (
      <div>
        <div class='flex-row bonus-lessons-list-container'>
          {bonusLessons.map((lesson, index) =>
            <LessonCardSmall
              key={`bonus-lesson-${index}`}
              lesson={lesson}
            />
          )}
        </div>

        {addMoreButton}
      </div>
    );

  } else {
    bonusLessonsElement = (
      <div class='flex-row bonus-lessons-list-container'>
        <BonusLessonLocked />
        <BonusLessonLocked />
        <BonusLessonLocked />
        <BonusLessonLocked />
      </div>
    );
  }


  return (
    <div class='flex-column bonus-lessons-container'>
      <h3>Bonus Lessons</h3>
      {bonusText}
      {bonusLessonsElement}
    </div>
  );
}

export default BonusLessons;