import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom"

import { updateSavedLearningModules } from "../utilities/auth/authApi";
import toast from 'react-hot-toast';
import VectorIcon from "../utilities/vectorIcon";

import lessonSlice from "../../store/lesson";
import savedLessonsSlice from "../../store/savedlessons";


function HeaderLesson() {

  // Define variables
  const dispatch = useDispatch();
  const [saveEnabled, setSaveEnabled] = useState(true);
  const currentLesson = useSelector((state) => state.lesson.currentLesson);
  const id = currentLesson.id;
  const imageUrl = currentLesson.imageUrl;
  const title = currentLesson.title;
  const isSaved = currentLesson.isSaved;
  // const isSaved = useSelector((state) => state.lesson.isSaved);
  const icon = isSaved ? 'bookmarkFill' : 'bookmarkOutline';


  // Define functions
  function tryToSaveLesson() {
    if( saveEnabled ) {
      saveLesson();
    }
  }

  function saveLesson() {
    setSaveEnabled(false);
    const action = isSaved ? 'remove' : 'add';

    const object = {
      event: action,
      learning_module_id: id
    };

    updateSavedLearningModules(object).then((response) => {
      if( response?.data?.success ) {
        // dispatch({ type: 'setIsSaved', payload: {isSaved: !isSaved}});
        dispatch(lessonSlice.actions.setIsSaved({isSaved: !isSaved}));
        // dispatch({ type: 'invalidateSavedLessons'});
        dispatch(savedLessonsSlice.actions.invalidateSavedLessons());
      } else {
        toast.error(response.error);
      }
    }).finally(() => {
      setTimeout(() => setSaveEnabled(true), 1200);
    });
  }


  return (
    <div
      class='header-lesson-wrapper'
      style={{backgroundImage: `url('${imageUrl}')`}}
    >

      <div class='header-lesson-container'>

        <div class='flex-row header-lesson-actions'>

          <Link to='/' title='Close'>
            <VectorIcon icon='close' color={'#ffffff'} />
          </Link>

          <button
            type='button'
            class='clean-button pointer'
            onClick={() => tryToSaveLesson()}
          >
            <VectorIcon icon={icon} color={'#ffffff'} />
          </button>

        </div>

        <div class='header-lesson-title-container'>
          <h1>{title}</h1>
        </div>

      </div>
    </div>
  );
};

export default HeaderLesson;