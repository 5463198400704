import React from 'react';
import { useState } from 'react';
import { useDispatch } from "react-redux";

import alertMessage from '../../utilities/alertMessage';
import apiCall from '../../utilities/auth/apiCall';
import { logExerciseStart, translateExpression } from '../../utilities/auth/authApi';
import sendExerciseData from '../../../functions/sendExerciseData';
import { Mixpanel } from "../../utilities/analytics/mixpanelHelpers";

import ProgressSteps from '../../utilities/progressSteps';
import ExpressionMeaning from "./expressionMeaning";
import ExpressionSentence from "./expressionSentence";
import LessonCompleteModal from '../../utilities/lessonCompleteModal';
import TranslateToggle from '../../utilities/translate/translateToggle';

import dashboardSlice from '../../../store/dashboard';

let responsesArray = [];


function ExpressionPractice(props) {

  // Dispatch
  const dispatch = useDispatch();

  function clearDashboardData() {
    // dispatch({ type: 'clearDashboardData' });
    dispatch(dashboardSlice.actions.clearDashboardData());
  }


  // Define variables
  const [currentStep, setCurrentStep] = useState(1);
  const [exerciseId, setExerciseId] = useState(null);
  const [translateToggle, setTranslateToggle] = useState(false);
  const [translateContent, setTranslateContent] = useState(null);

  const id = props.id;
  const completeElement = props.completeElement;
  const setCompleteElement = props.setCompleteElement;
  const expression = props.expression;
  const totalSteps = expression?.steps?.totalSteps ? expression.steps.totalSteps : 3;


  // Define functions
  async function addResponse(responseObject) {
    responsesArray.push(responseObject);
    return responsesArray;
  }

  function nextStep(step) {
    setCurrentStep(step);
  }

  function logStart() {

    setCurrentStep(2);

    const payload = {
      lesson_id: id,
      started: true,
      lesson_type: 'Expression'
    }

    apiCall(logExerciseStart, payload).then((response) => {
      if( response.success ) {
        setExerciseId(response.data.exerciseId);
      }
    });
  }

  function completeLesson() {

    // Send the responses to the server
    const payload = {
      lessonType: 'Expression',
      exerciseId: exerciseId,
      lessonId: id,
      responsesToSave: responsesArray
    }

    sendExerciseData(payload).then((response) => {

      if( response.success ) {

        setCompleteElement(<LessonCompleteModal data={response.data} />);

        // Make the dashboard recall lesson data
        clearDashboardData();

        // Clear the responses array
        // in case the learner goes directly to a new lesson
        responsesArray = [];

        // Log the event
        Mixpanel.track('Event: Lesson Complete', {'Lesson ID': id, 'Lesson Type': 'Expression'});

      } else {
        alertMessage(response.error, 'error');
      }

    });

  }


  // Screen components
  let currentScreen;
  if( currentStep === 1 ) {
    currentScreen = (
      <ExpressionMeaning
        logStart={logStart}
        expression={expression}
        translateToggle={translateToggle}
        translation={translateContent ? translateContent.meaning : null}
      />
    );
  } else {
    currentScreen = (
      <ExpressionSentence
        addResponse={addResponse}
        nextStep={nextStep}
        expression={expression}
        currentStep={currentStep}
        completeLesson={completeLesson}
        isLastScreen={totalSteps === currentStep}
        translateToggle={translateToggle}
        translations={translateContent ? translateContent.sentences : null}
      />
    );
  }


  return (
    <div>
      {completeElement}
      <div style={{padding: '15px 30px'}}>
        <ProgressSteps
          steps={totalSteps}
          currentStep={currentStep}
        />
      </div>
      {currentScreen}

      <div class='expression-translate-toggle-container'>
        <TranslateToggle
          id={id}
          apiMethod={translateExpression}
          translateToggle={translateToggle}
          setTranslateToggle={setTranslateToggle}
          translateContent={translateContent}
          setTranslateContent={setTranslateContent}
          lessonType={'Expression'}
        />
      </div>
    </div>
  );
}

export default ExpressionPractice;