import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  lessons: []
}

export const name = 'savedLessons';

const savedLessonsSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeSavedLessons: (state, action) => {
      state.initialized = action.payload.initialized;
      state.lessons = action.payload.lessons;
    },

    invalidateSavedLessons: (state, action) => {
      state.initialized = false;
    },
  },
});

export const { actions, reducer } = savedLessonsSlice;
export default savedLessonsSlice;


// // ---
// export function SavedLessonsReducer(state = initialSate, action) {

//   if( action.type === 'initializeSavedLessons') {
//     return {
//       ...state,
//       initialized: action.payload.initialized,
//       lessons: action.payload.lessons
//     }
//   }

//   if( action.type === 'invalidateSavedLessons') {
//     return {
//       ...state,
//       initialized: false
//     }
//   }

//   return state;
// }