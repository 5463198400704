import React from "react";
import { useState, useEffect } from 'react';

import '../../styles/profileSettings.css';

import toast from 'react-hot-toast';
import apiCall from "../utilities/auth/apiCall";
import { getPreferenceData, updatePreferenceData } from "../utilities/auth/authApi";

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import ProfileSubNav from "./profilesubnav";
import ProfileToggle from "./profiletoggle";
import SelectPill from "../start/selectPill";
// import PhoneInput from "react-phone-input-2";

const days = [
  {display: 'Sun', value: 'sunday'},
  {display: 'Mon', value: 'monday'},
  {display: 'Tues', value: 'tuesday'},
  {display: 'Wed', value: 'wednesday'},
  {display: 'Thur', value: 'thursday'},
  {display: 'Fri', value: 'friday'},
  {display: 'Sat', value: 'saturday'},
];


function Preferences() {

  // Define variables
  const [loading, setLoading] = useState(false);
  // const [countryCode, setCountryCode] = useState('91');
  // const [phoneNumber, setPhoneNumber] = useState(null);
  const [selectedEmailDays, setSelectedEmailDays] = useState([]);
  // const [selectedWhatsappDays, setSelectedWhatsappDays] = useState([]);
  const [autoplayAudio, setAutoplayAudio] = useState(false);
  const [notificationCommunityFeedback, setNotificationCommunityFeedback] = useState(false);
  const [notificationFlowPromotions, setNotificationFlowPromotions] = useState(false);
  const [askToShareWithOrg, setAskToShareWithOrg] = useState('hide');


  // Component load actions
  useEffect(() => {
    apiCall(getPreferenceData).then((response) => {
      if( response.success ) {
        setSelectedEmailDays(response.data.email);
        // setSelectedWhatsappDays(response.data.whatsapp);
        setNotificationCommunityFeedback(response.data.communityFeedback);
        setNotificationFlowPromotions(response.data.flowPromotions);
        setAutoplayAudio(response.data.autoplay);

        if( response.data.hasOwnProperty('askToShareWithOrg') ) {
          setAskToShareWithOrg(response.data.askToShareWithOrg)
        }
      }
    });
  }, []);



  // Define functions
  // function handlePhoneNumber( value, country) {
  //   const countryCode = country.dialCode;
  //   const phone = value.replace(countryCode, '');

  //   setCountryCode(countryCode);
  //   setPhoneNumber(phone);
  // }

  function handleEmailClick(value) {
    const array = selectedEmailDays;
    let newArray;

    if( array.includes(value) ) {
      newArray = array.filter(item => item !== value);
    } else {
      newArray = [...selectedEmailDays, value];
    }

    setSelectedEmailDays(newArray);
  }

  // function handleWhatsappClick(value) {
  //   const array = selectedWhatsappDays;
  //   let newArray;

  //   if( array.includes(value) ) {
  //     newArray = array.filter(item => item !== value);
  //   } else {
  //     newArray = [...selectedWhatsappDays, value];
  //   }

  //   setSelectedWhatsappDays(newArray);
  // }

  function handleReminderSubmit() {

    setLoading(true);

    const payload = {
      email_days: selectedEmailDays,
    };

    submitData(payload);
  }

  function handleCommunityFeedbackClick(name, value) {
    setNotificationCommunityFeedback(value)
    submitData({[name]: value});
  }

  function handlePromotionClick(name, value) {
    setNotificationFlowPromotions(value)
    submitData({[name]: value});
  }

  function handleAutoplayClick(name, value) {
    localStorage.setItem("autoplayAudio", value);
    setAutoplayAudio(value)
    submitData({[name]: value});
  }

  function handleShareClick(name, value) {
    setAskToShareWithOrg(value)
    submitData({[name]: value});
  }

  function submitData(payload) {
    apiCall(updatePreferenceData, payload).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          toast.success('Preferences successfully updated');
        }, 1000);
      } else if( response.error ) {
        setTimeout(() => {
          toast.error(response.error);
        }, 1000);
      }
    }).finally(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }


  // Screen components
  let askToShareWithOrgElement;
  if( askToShareWithOrg != 'hide' ) {
    askToShareWithOrgElement = (
      <div>
        <h3 style={{marginTop: '45px'}}>Recording Sharing Permission</h3>
        <div class='pathway-wrapper' style={{maxWidth: '420px'}}>
          <ProfileToggle
            id={'share-responses-with-org'}
            name={'share_responses_with_org'}
            label={"Share your voice recordings with your organization's administrators (staff or teachers) to get progress reviews and feedback"}
            value={askToShareWithOrg}
            handlePreferenceChange={handleShareClick}
          />
        </div>
        <br />
        <hr />
      </div>
    );
  }


  return (
    <div class='wrapper gray-background'>
      <Nav />

      <div class='main-body' style={{marginBottom: '60px'}}>
        <HeaderMain title="Profile" />
        <div class='main-body-content profile'>

          <ProfileSubNav />

          <h3 style={{marginTop: '45px'}}>Reminders</h3>
          <p>Set frequent reminders to reach your goal</p>

          <div class='pathway-wrapper' style={{maxWidth: '420px'}}>
            <h4 style={{marginBottom: '10px'}}>Email</h4>
            <div class='flex-row reminder-select-wrapper'>
              {days.map((day, index) =>
                <SelectPill
                  key={`email-day-select-${index}`}
                  text={day.display}
                  value={day.value}
                  handleClick={handleEmailClick}
                  selected={selectedEmailDays?.includes(day.value)}
                />
              )}
            </div>
          </div>

          {/* <div class='pathway-wrapper' style={{maxWidth: '420px', marginTop: '30px'}}>
            <h4 style={{marginBottom: '10px'}}>WhatsApp</h4>
            <div class='flex-row reminder-select-wrapper'>
              {days.map((day, index) =>
                <SelectPill
                  key={`whatsapp-day-select-${index}`}
                  text={day.display}
                  value={day.value}
                  handleClick={handleWhatsappClick}
                  selected={selectedWhatsappDays?.includes(day.value)}
                />
              )}
            </div>

            <PhoneInput
              country={'in'}
              enableSearch={true}
              onChange={(value, country) => handlePhoneNumber(value, country)}
              inputClass={'input'}
            />

          </div> */}

          <button
            class={`button ${loading ? 'is-loading' : null}`}
            onClick={() => handleReminderSubmit()}
          >
            Save Reminder Preferences
          </button>

          <br />
          <hr />

          <h3 style={{marginTop: '45px'}}>Notifications</h3>
          <div class='pathway-wrapper' style={{maxWidth: '420px'}}>
            <ProfileToggle
              id={'notification-community-feedback-field'}
              name={'community_feedback'}
              label={'Send community feedback email notifications'}
              value={notificationCommunityFeedback}
              handlePreferenceChange={handleCommunityFeedbackClick}
            />

            <ProfileToggle
              id={'notification-flow-promotions-field'}
              name={'flow_promotions'}
              label={'Send FLOW promotional  emails'}
              value={notificationFlowPromotions}
              handlePreferenceChange={handlePromotionClick}
            />
          </div>


          <br />
          <hr />

          {askToShareWithOrgElement}

          <h3 style={{marginTop: '45px'}}>Autoplay</h3>
          <div class='pathway-wrapper' style={{maxWidth: '420px'}}>
            <ProfileToggle
              id={'autoplay-audio-field'}
              name={'autoplay'}
              label={'Autoplay lesson audio'}
              value={autoplayAudio}
              handlePreferenceChange={handleAutoplayClick}
            />

            <div class='autoplay-guide'>
              <p>Not all browsers allow autoplay by default, even if you choose "Yes" above. See the guides below to enable autoplay on your browser.</p>
              <p>
                <i>Please also note that Safari and Chrome on mobile devices does not allow autoplay.</i>
              </p>
              <ul>
                <li><a href='https://browserhow.com/how-to-allow-or-block-auto-play-sound-access-in-safari-mac/' target='_blank' rel="noreferrer">Safari Guide for desktops ( and laptops )</a></li>
                <li><a href='https://support.mozilla.org/en-US/kb/block-autoplay' target='_blank' rel="noreferrer">Firefox Guide for desktops ( and laptops )</a></li>
                <li>Chrome does allow autoplay by defualt</li>
              </ul>
            </div>
          </div>


        </div>
      </div>
      <BottomNav />
    </div>
  )
}

export default Preferences;