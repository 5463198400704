import ProgressBar from "../progressBar";
import VectorIcon from "../vectorIcon";


function PathwayProgressReportLevel(props) {

  // Define variables
  const levelNumber = props.levelNumber;
  const level = props.level;
  const started = level.started;
  const complete = level.complete;
  const progress = level.progress;
  const iconColor = complete ? '#BC0181' : '#DCDCDC';


  return (
    <div class={`flex-row progress-report-level ${started ? null : 'disabled'}`}>
      <div class='flex-row progress-report-level-inner'>
        <VectorIcon icon={'badge'} color={'#8C0E85'} />
        <h5>Level {levelNumber}</h5>
        <div class='report-progress-bar-container'>
        <ProgressBar width={`${progress * 100}%`} />
        </div>
      </div>
      <VectorIcon icon={'checkMarkCircle'} color={iconColor} />
    </div>
  );
}

export default PathwayProgressReportLevel;