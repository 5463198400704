import React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import '../../styles/expression.css';

import alertMessage from '../utilities/alertMessage';
import { reportError } from "../../functions/sentry";
import apiCall from '../utilities/auth/apiCall';
import { getExpression, updateSavedExpressions } from '../utilities/auth/authApi';

import LoadingBasic from "../utilities/loadingbasic";

import ExpressionHeader from './expressionHeader';
import ExpressionScreenSwitcher from './expressionScreenSwitcher';
import ExpressionPractice from './screens/expressionPractice';
import ExpressionRelated from './screens/expressionRelated';
import ExpressionNoAccess from './screens/expressionNoAccess';

import lessonSlice from '../../store/lesson';
import expressionSlice from '../../store/expression';
import savedLessonsSlice from '../../store/savedlessons';


function ExpressionScreen() {

  // Dispatch
  const dispatch = useDispatch();

  function setActivePlaying(status) {
    // dispatch({ type: 'setActivePlaying', payload: status});
    dispatch(lessonSlice.actions.setActivePlaying(status));
  }

  function setExpression(payload) {
    dispatch({ type: 'setExpression', payload: payload});
    dispatch(expressionSlice.actions.setExpression(payload));
  }

  function setIsSaved(value) {
    // dispatch({ type: 'setExpressionIsSaved', payload: value});
    dispatch(expressionSlice.actions.setExpressionIsSaved(value));
  }

  function invalidateSavedLessons() {
    // dispatch({ type: 'invalidateSavedLessons'});
    dispatch(savedLessonsSlice.actions.invalidateSavedLessons());
  }


  // Define variables
  const { id } = useParams();
  const [completeElement, setCompleteElement] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentScreen, setCurrentScreen] = useState('loading');
  const expression = useSelector((state) => state.expression.lessonData);
  const isSaved = useSelector((state) => state.expression.isSaved);
  const relatedLessons = expression?.relatedLessons ? expression.relatedLessons : [];
  const title = expression?.title ? expression.title : 'Loading ...';
  const learnerHasAccessToLesson = expression.learnerHasAccessToLesson;


  // Page load actions
  useEffect(() => {

    setActivePlaying(false);

    // Ensure everything is reset
    reset();

    if( id ) {
      apiCall(getExpression, id).then((response) => {

        if( response.success ) {
          const lessonInfo = response.data.expression;

          const reduxInfo = {
            id: id,
            lessonData: lessonInfo,
            isSaved: lessonInfo.isSaved,
            isIelts: lessonInfo.isIelts
          };

          setExpression(reduxInfo);

          // Take just a bit so the screen doesn't jump around
          setTimeout(() => {
            setIsLoading(false);
            setCurrentScreen('practice');
          }, 700);
        } else {
          alertMessage(response.error, 'error');
          reportError(response.error);
        }

      });
    } else {
      // Handle
    }

  }, [id]);


  // Define functions
  function saveLesson() {

    const payload = {
      id: id,
      is_saved: isSaved
    }

    apiCall(updateSavedExpressions, payload).then((response) => {
      if( response.success ) {
        setIsSaved(response.data.isSaved);
        invalidateSavedLessons();
      } else {
        alertMessage(response.error, 'error');
      }
    });
  }

  function changeScreen(screen) {
    setCurrentScreen(screen);
  }

  function reset() {
    setCompleteElement(null);
    setCurrentScreen('loading');
  }


  // Screen components
  let currentScreenElement = <LoadingBasic />;

  if( currentScreen === 'related' ) {

    currentScreenElement = (
      <ExpressionRelated
        relatedLessons={relatedLessons}
      />
    );

  } else if ( currentScreen === 'practice' ) {

    currentScreenElement = (
      <ExpressionPractice
        id={id}
        expression={expression}
        completeElement={completeElement}
        setCompleteElement={setCompleteElement}
      />
    );

  }

  if( !isLoading && !learnerHasAccessToLesson && currentScreen === 'practice' ) {
    currentScreenElement = <ExpressionNoAccess />;
  }


  return (
    <div class='wrapper'>
      <div class='lesson-body'>

        <div class='lesson-left-column'>
          <p class='related-lessons-title'>Related Lessons</p>
          <ExpressionRelated
            relatedLessons={relatedLessons}
          />
        </div>

        <div class='lesson-right-column'>

          <ExpressionHeader
            title={title}
            saveLesson={saveLesson}
          />

          <ExpressionScreenSwitcher
            currentScreen={currentScreen}
            changeScreen={changeScreen}
          />

          {currentScreenElement}

        </div>

      </div>
    </div>
  );
}

export default ExpressionScreen;