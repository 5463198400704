import { useSelector, useDispatch } from "react-redux";

import alertMessage from "../utilities/alertMessage";
import apiCall from '../utilities/auth/apiCall';
import { updatePreferenceData } from "../utilities/auth/authApi";
import profileSlice from "../../store/profile";

//
function OrgShare() {

  // Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    dispatch(profileSlice.actions.updateField({field, value}));
  }

  // Define variables
  const askToShareWithOrg = useSelector((state) => state.profile.askToShareWithOrg);


  // Define functions
  function handleClick(value) {
    const payload = { share_responses_with_org: value };

    apiCall(updatePreferenceData, payload).then((response) => {
      if( response.success ) {
        alertMessage('Your preference has been saved.');
        updateField('askToShareWithOrg', false);
      } else {
        alertMessage(response.error, 'error');
      }
    });
  }


  // Screen components
  let bodyContent;
  if( askToShareWithOrg ) {
    bodyContent = (
      <div class='flex-column org-share-question-container'>

        <div class='flex-column' style={{maxWidth: '700px'}}>
          <p>Share your voice recordings?</p>
          <p>Share your voice recordings with your organization's administrators (staff or teachers) to get progress reviews and feedback.</p>
          <p>&nbsp;</p>
        </div>

        <div class='flex-row'>
          <button
            type='button'
            class='button'
            onClick={() => handleClick(true)}
          >Yes, I agree to share</button>

          <button
            type='button'
            class='button'
            onClick={() => handleClick(false)}
          >No, I prefer not to share</button>
        </div>

      </div>
    );
  }


  return (
    <>{bodyContent}</>
  );
}

export default OrgShare;