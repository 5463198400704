import mixpanel from 'mixpanel-browser';
mixpanel.init('e25609f52605713c51cabb40e5444708');


const env_check = process.env.NODE_ENV === 'production';


let actions = {
  identify: (id) => {
    if (env_check) {
      mixpanel.identify(id);
    } else {
      console.log('Mixpanel Identify: ', id);
    }
  },
  alias: (id) => {
    if (env_check) {
      mixpanel.alias(id);
    } else {
      console.log('Mixpanel Alias: ', id);
    }
  },
  track: (name, props) => {
    if( !props ) { props = {} }
    props.platform = 'Web';

    if (env_check) {
      mixpanel.track(name, props);
    } else {
      console.log('Mixpanel Track: ', name);
      console.log('props', props);
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        mixpanel.people.set(props);
      } else {
        console.log('Mixpanel People: ', props);
      }
    },
  },
};

export let Mixpanel = actions;