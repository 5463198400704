import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  allLessons: [],
  displayLessons: [],
  page: 1,
  chartData: null
}

export const name = 'progressLessons';

const progressLessonsSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeProgressLessons: (state, action) => {
      state.initialized = true;
      state.allLessons = action.payload.allLessons;
      state.displayLessons = action.payload.displayLessons;
      state.chartData = action.payload.chartData;
    },

    setProgressDisplayLessons: (state, action) => {
      state.displayLessons = action.payload;
    },

    setProgressPage: (state, action) => {
      state.page = action.payload;
    },

  },
});

export const { actions, reducer } = progressLessonsSlice;
export default progressLessonsSlice;



// // ---
// export function progressLessonsReducer(state = initialSate, action) {


//   // if( action.type === 'initializeProgressLessons') {
//   //   return {
//   //     ...state,
//   //     initialized: true,
//   //     allLessons: action.payload.allLessons,
//   //     displayLessons: action.payload.displayLessons,
//   //     chartData: action.payload.chartData,
//   //   }
//   // }

//   if( action.type === 'setProgressDisplayLessons') {
//     return {
//       ...state,
//       displayLessons: action.payload
//     }
//   }

//   if( action.type === 'setProgressPage') {
//     return {
//       ...state,
//       page: action.payload
//     }
//   }


//   return state;
// }