function ProfileToggle(props) {

  // Define variables
  const disabled = props.disabled || false;
  let toggleWrapperClass = 'action-toggle-wrapper';
  if( props.value ) { toggleWrapperClass += ' selected' }


  return (
    <div class='profile-input-wrapper'>
      <label>{props.label}</label>
      <div class='profile-input'>
        <button
          class={toggleWrapperClass}
          onClick={() => props.handlePreferenceChange(props.name, !props.value)}
          disabled={disabled}
        >
          <div class='toggle-ball'></div>
        </button>
      </div>
    </div>
  );
}

export default ProfileToggle;