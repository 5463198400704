import { useEffect } from 'react';


function IntroTour () {

  // Component load actions
  useEffect(() => {
    setTimeout(() => showStart(), 500);
  }, []);


  // Define functions
  function showStart() {
    const element = document.querySelector(".week-list-container.is-this-week .lesson-card-wrapper:first-of-type");
    if( element ) {
      // Create the wrapper
      const wrapper = document.createElement('div');
      wrapper.classList.add('start-here-wrapper');

      // Create the arrow
      const arrow = document.createElement('div');
      arrow.classList.add('start-here-arrow');
      arrow.classList.add('fade-in-on-load');
      arrow.classList.add('fade-slow');
      wrapper.appendChild(arrow);

      // Create the container
      const container = document.createElement('div');
      container.classList.add('start-here-container');
      container.classList.add('fade-in-on-load');
      wrapper.appendChild(container);


      // Insert the outer div into the DOM
      element.appendChild(wrapper); // Append to the body
    }
  }


  return (
    <></>
  );
}

export default IntroTour;