import React from 'react';
import { useState, useEffect } from 'react';

import apiCall from '../utilities/auth/apiCall';
import { orgGroupCreateLearner, sendSmsAccountVerifyCode } from '../utilities/auth/authApi';
import SignupForm from '../start/signupForm';


function OrgRegisterForm(props) {

  // Define variables
  const updateField = props.updateField;
  const changeCurrentScreen = props.changeCurrentScreen;
  const runLoginProcess = props.runLoginProcess;
  const orgData = props.orgData;
  const organizationGroupId = orgData.organizationGroupId;
  const promoCode = orgData.promoCode;
  const includeLastName = orgData.includeLastName;
  const phoneNumberMessage = orgData.phoneNumberMessage;
  const indonesiaUniversity = orgData?.organizationId ? orgData.organizationId === 119 : null;
  const usePasswordFieldByDefault = orgData.registerDefaultToEmail;
  const registerDefaultPasswordType = orgData.registerDefaultPasswordType;
  const registerHidePhoneAsPassword = orgData.registerHidePhoneAsPassword;

  const referrer = document.referrer;

  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorText, setErrorText] = useState([]);


  // Component load actions
  useEffect(() => {

    // Set the register url so we can get back to it
    const registerUrl = window.location.href;
    localStorage.setItem('registerUrl', registerUrl);

  }, []);


  // Define functions
  function register(payload) {

    payload.organization_group_id = organizationGroupId;

    apiCall(orgGroupCreateLearner, payload).then((response) => {
      if( response.success ) {
        runLoginProcess(response.data);
      } else {
        setErrorText(response.error);
      }
    }).finally(() => {
      setTimeout( setButtonLoading(false), 400);
    });
  }

  function smsRegister(payload) {

    payload.organization_group_id = organizationGroupId;

    // Set the smsPayload so we can use it later
    updateField('smsPayload', payload);

    apiCall(sendSmsAccountVerifyCode, payload).then((response) => {
      if( response.success ) {
        changeCurrentScreen('verify');
      } else {
        setErrorText(response.error);
      }
    }).finally(() => {
      setTimeout( setButtonLoading(false), 400);
    });

  }


  return(
    <section class='section-auth-form'>
      <SignupForm
        errorText={errorText}
        promoCode={promoCode}
        referrer={referrer}
        buttonLoading={buttonLoading}
        setButtonLoading={setButtonLoading}
        changeCurrentScreen={changeCurrentScreen}
        register={register}
        smsRegister={smsRegister}
        // usePasswordFieldByDefault={usePasswordFieldByDefault}
        registerDefaultPasswordType={registerDefaultPasswordType}
        registerHidePhoneAsPassword={registerHidePhoneAsPassword}
        includeLastName={includeLastName}
        phoneNumberMessage={phoneNumberMessage}
        indonesiaUniversity={indonesiaUniversity}
        // disableLogin={true}
      />
    </section>
  );
}

export default OrgRegisterForm;