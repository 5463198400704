// // Tutorial
// // https://egghead.io/lessons/react-add-redux-to-an-existing-react-application

// import { createStore, combineReducers } from "redux"
// import { profileReducer } from "./profile";
// import { LessonReducer } from "./lesson";
// import { AllSeriesReducer } from "./allseries";
// import { SavedLessonsReducer } from "./savedlessons";
// import { notificationsReducer } from "./notifications";
// import { postsReducer } from "./posts";
// import { coursesReducer } from "./courses";
// import { packageReducer } from "./package";
// import { ExpressionReducer } from "./expression";
// import { AiTalkReducer } from "./alTalk";
// import { BoosterReducer } from "./booster";
// import { dashboardDataReducer } from "./dashboard";
// import { progressLessonsReducer } from "./progressLessons";

// export const store = createStore(
//   combineReducers({
//     dashboardData: dashboardDataReducer,
//     profile: profileReducer,
//     lesson: LessonReducer,
//     progressLessons: progressLessonsReducer,
//     allSeries: AllSeriesReducer,
//     savedLessons: SavedLessonsReducer,
//     notifications: notificationsReducer,
//     posts: postsReducer,
//     courses: coursesReducer,
//     package: packageReducer,
//     expression: ExpressionReducer,
//     aiTalk: AiTalkReducer,
//     booster: BoosterReducer
//   })
// );

// // --

// https://remarkablemark.org/blog/2021/05/23/add-redux-to-react-typescript-app/

// import { configureStore } from '@reduxjs/toolkit';
import { createStore, combineReducers } from 'redux';

import profileSlice from './profile';
import allSeriesSlice from "./allseries";
import aiTalkSlice from "./aiTalk";
import boosterSlice from "./booster";
import coursesSlice from "./courses";
import dashboardSlice from "./dashboard";
import expressionSlice from "./expression";
import lessonSlice from "./lesson";
import notificationsSlice from "./notifications";
import packageSlice from "./package";
import postsSlice from "./posts";
import progressLessonsSlice from "./progressLessons";
import savedLessonsSlice from "./savedlessons";


export const store = createStore(
  combineReducers({
    profile: profileSlice.reducer,
    allSeries: allSeriesSlice.reducer,
    aiTalk: aiTalkSlice.reducer,
    booster: boosterSlice.reducer,
    courses: coursesSlice.reducer,
    dashboardData: dashboardSlice.reducer,
    expression: expressionSlice.reducer,
    lesson: lessonSlice.reducer,
    notifications: notificationsSlice.reducer,
    package: packageSlice.reducer,
    posts: postsSlice.reducer,
    progressLessons: progressLessonsSlice.reducer,
    savedLessons: savedLessonsSlice.reducer,
  })
)