import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import alertMessage from '../alertMessage';
import { getPublicProfile } from "../auth/authApi";

import VectorIcon from '../vectorIcon';
import PathwayProgressReportLevel from './pathwayProgressReporLevel';


function PathwayProgressReport() {

  // Define variables
  const publicProfileId = useSelector((state) => state.profile.publicProfileId);
  const [pathwayData, setPathwayData] = useState({});
  const pathwayName = pathwayData.pathwayName;
  const activeLevel = pathwayData.activeLevel;
  const currentPathwayProgress = pathwayData.currentPathwayProgress;


  // Component load actions
  useEffect(() => {
    if( publicProfileId ) {
      getPublicProfile(publicProfileId).then((response) => {
        if( response?.data?.success ) {
          setPathwayData(response.data.learner.pathwayData);
        } else {
          alertMessage(response.error, 'error');
        }
      });
    }
  }, [publicProfileId]);


  // Screen components
  let reportLevelsElement;
  if( pathwayName ) {
    reportLevelsElement = (
      <div class='progress-report-level-container'>
        <PathwayProgressReportLevel level={currentPathwayProgress.levelOne} levelNumber={'1'} />
        <PathwayProgressReportLevel level={currentPathwayProgress.levelTwo} levelNumber={'2'} />
        <PathwayProgressReportLevel level={currentPathwayProgress.levelThree} levelNumber={'3'} />

        <div class='flex-row progress-report-level' style={{opacity: .5}}>
          <div class='flex-row progress-report-level-inner'>
            <VectorIcon icon={'certificate'} color={'#8C0E85'} size={'1.7rem'} />
            <h5 style={{marginLeft: '10px'}}>Certificate</h5>
          </div>
          <VectorIcon icon={'checkMarkCircle'} color={'#DCDCDC'} />
        </div>
      </div>
    );
  }


  return (
    <div class='flex-column'>
      <div class='flex-column progress-report-container'>
        <h3>{pathwayName} {activeLevel} Pathway</h3>
        <h4>Progress Report</h4>
        {reportLevelsElement}

      </div>

      <p style={{marginTop: '15px'}}>
        <a
          href='https://www.flowspeak.io/articles/pathways-level-up-your-speaking'
          rel="noreferrer"
          target='_blank'
          style={{color: '#6D6D6D', textDecoration: 'underline', fontSize: '.9rem'}}
        >Learn more about FLOW Speak Pathways</a>
      </p>
    </div>
  );
}

export default PathwayProgressReport;