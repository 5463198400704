import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currencyCode: 'USD',
  // packageId: 'price_1KPKygIs167BP3avGppfNq7e',
  packageId: null,
  promoCode: null,
  packageData: null
}

export const name = 'package';

const packageSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    setPackageCurrencyCode: (state, action) => {
      state.currencyCode = action.payload.currencyCode;
    },

    setPackageId: (state, action) => {
      state.packageId = action.payload.packageId;
    },

    setPromoCode: (state, action) => {
      state.promoCode = action.payload.promoCode;
    },

    setPackageData: (state, action) => {
      state.packageData = action.payload.packageData;
    },

  },
});

export const { actions, reducer } = packageSlice;
export default packageSlice;


// // ----
// export function packageReducer(state = initialSate, action) {

//   // if( action.type === 'setPackageCurrencyCode') {
//   //   return {
//   //     ...state,
//   //     currencyCode: action.payload.currencyCode
//   //   };
//   // }

//   // if( action.type === 'setPackageId') {
//   //   return {
//   //     ...state,
//   //     packageId: action.payload.packageId
//   //   };
//   // }

//   if( action.type === 'setPromoCode') {
//     return {
//       ...state,
//       promoCode: action.payload.promoCode
//     };
//   }

//   if( action.type === 'setPackageData') {
//     return {
//       ...state,
//       packageData: action.payload.packageData
//     };
//   }

//   return state;
// }