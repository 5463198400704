import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";

import Avatar from 'react-avatar-edit'
import axios from 'axios';

import { Mixpanel } from './analytics/mixpanelHelpers';
import alertMessage from "./alertMessage";
import { updatePersonalInformation } from "./auth/authApi";
import { languageCodes } from "./languagecodes";
import { countryList } from "./countrylist";
import { getToken } from "./auth/tokenHelpers";
import VectorIcon from "./vectorIcon";
import DefaultAvatarPicker from './defaultAvatarPicker';

import profileSlice from '../../store/profile';
import dashboardSlice from '../../store/dashboard';


function DashboardProfileSetupModal(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    dispatch(profileSlice.actions.updateField({field, value}));
  }

  function incrementProfileSetup(field, value) {
    setTimeout(() => {
      dispatch(dashboardSlice.actions.incrementProfileSetup({field, value}));
    }, 1000);
  }


  // Define variables
  const [showAvatarPicker, setShowAvatarPicker] = useState(false);
  const [defaultAvatar, setDefaultAvatar] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [countryComplete, setCountryComplete] = useState(false);
  const [nativeComplete, setNativeComplete] = useState(false);
  const [avatarComplete, setAvatarComplete] = useState(false);
  const [avatarButtonDisabled, setAvatarButtonDisabled] = useState(true);
  const [avatarImage, setAvatarImage] = useState('');
  const closeModal = props.closeModal;
  const profileSetup = props.profileSetup;
  const askCountry = profileSetup.askCountry;
  const askNativeLanguage = profileSetup.askNativeLanguage;
  const askAvatar = profileSetup.askAvatar;


  // Component load actions
  useEffect(() => {

    if( askCountry != true ) { setCountryComplete(true) }
    if( askNativeLanguage != true ) { setNativeComplete(true) }
    if( askAvatar != true ) {
      setAvatarImage(askAvatar);
      setAvatarComplete(true);
    }

  }, []);


  // Define functions
  function saveData(name, value, setter, field) {

    const payload = {
      user: {
        [name]: value
      }
    }

    updatePersonalInformation(payload).then((response) => {
      if( response && response.data ) {
        if( response.data.success ) {
          setter(true);
          incrementProfileSetup(field, value);
        } else {
          alertMessage(response.data.error);
        }
      } else {
        alertMessage('Sorry, there was a problem', 'error');
      }
    }).finally(() => {
      // changeElement(element, true);
    });

    Mixpanel.track('Event: Profile Setup - save data', {field: field});
  }

  function handleCountryChange(event) {
    const value = event.target.value;
    saveData('country', value, setCountryComplete, 'askCountry');
  }

  function handleLanguageChange(event) {
    const value = event.target.value;
    saveData('native_language', value, setNativeComplete, 'askNativeLanguage');
  }

  function handleDefaultAvatar() {
    saveData('avatar_url_stored', defaultAvatar, setAvatarComplete, 'askAvatar');
    setAvatarImage(defaultAvatar);
    updateField('avatarUrl', defaultAvatar);
  }

  function onCrop(data) {
    setImageData(data);
    setAvatarButtonDisabled(false);
  }

  function onClose() {
    setImageData(null);
    setAvatarButtonDisabled(true);
  }

  function onBeforeFileLoad(element) {
    if(element.target.files[0].size > 3000000){
      alertMessage('Please choose a file that is less than 3MB', 'error');
      element.target.value = "";
    };
  }

  function handleImageData() {
    setAvatarButtonDisabled(true);

    fetch(imageData).then(res => res.blob()).then(blob => {

      const formData = new FormData();
      formData.append(`user[avatar]`, blob, 'avatar');

      const config = {
        method: 'post',
        url: 'user/update_personal_information',
        headers: { authorization: `Bearer ${getToken()}`, "Content-Type": "multipart/form-data" },
        data : formData
      };

      // Send the data to the server
      return axios(config).then(function (response) {

        if( response.data.success ) {
          const avatarUrl = response.data.avatarUrl;
          updateField('avatarUrl', avatarUrl);
          setAvatarImage(avatarUrl);
          setAvatarComplete(true);
          incrementProfileSetup('askAvatar', avatarUrl);
        } else {
          alertMessage(response.data.error, 'error');
          setAvatarButtonDisabled(false);
        }

      }).catch(function (error) {
        alertMessage('Sorry, there was an error', 'error');
        setAvatarButtonDisabled(false);
      });

    });

    Mixpanel.track('Event: Profile Setup - save data', {field: 'avatar'});
  }


  // Screen components
  let countryCompleteElement;
  let countryClass;
  if( countryComplete ) {
    countryClass = 'complete';
    countryCompleteElement = (
      <div class='profile-setup-complete-mark'>
        <VectorIcon icon='checkMarkCircle' color='#BC0181' size={'1.75rem'} />
      </div>
    );
  }

  let nativeCompleteElement;
  let nativeClass;
  if( nativeComplete ) {
    nativeClass = 'complete';
    nativeCompleteElement = (
      <div class='profile-setup-complete-mark'>
        <VectorIcon icon='checkMarkCircle' color='#BC0181' size={'1.75rem'} />
      </div>
    );
  }

  let avatarElement;
  let avatarCompleteElement;
  let avatarClass;
  if( avatarComplete ) {
    avatarClass = 'complete';
    avatarElement = (
      <div style={{marginBottom: '15px'}}>
        <img src={avatarImage} style={{maxWidth: '100px', borderRadius: '100px'}} />
      </div>
    );

    avatarCompleteElement = (
      <div class='profile-setup-complete-mark'>
        <VectorIcon icon='checkMarkCircle' color='#BC0181' size={'1.75rem'} />
      </div>
    );
  } else {

    if( showAvatarPicker ) {
      avatarElement = (
        <div>
          <DefaultAvatarPicker setDefaultAvatar={setDefaultAvatar} />

          <div style={{marginBottom: '30px'}}>
            <button
              class='profile-setup-avatar-picker-button'
              onClick={() => setShowAvatarPicker(!showAvatarPicker)}
            >or upload your own image</button>
          </div>

          <button
            class='button setup-profile-upload-button'
            onClick={() => handleDefaultAvatar()}
            disabled={!defaultAvatar}
          >Save</button>
        </div>
      );

    } else {
      avatarElement = (
        <div style={{width: '100%'}}>
          <div class='profile-input-wrapper'>
            <div class='profile-input setup-profile-upload-container'>
              <Avatar
                width={250}
                height={200}
                onCrop={onCrop}
                onClose={() => onClose()}
                onBeforeFileLoad={onBeforeFileLoad}
                src={''}
                exportAsSquare={true}
                exportMimeType={'image/jpeg'}
              />
            </div>

            <button
              class='profile-setup-avatar-picker-button'
              onClick={() => setShowAvatarPicker(!showAvatarPicker)}
            >or pick an Avatar from our list</button>

          </div>

          <button
            class='button setup-profile-upload-button'
            onClick={() => handleImageData()}
            disabled={avatarButtonDisabled}
          >Save</button>
        </div>
      );
    }
  }


  return (
    <div class='window-modal-wrapper'>
      <div class='window-modal profile-setup-modal'>

        <div class='window-modal-header'>
          <span class='icon'>&nbsp;</span>

          <div>
            <p>Welcome To</p>
            <h2>FLOW Speak</h2>
          </div>

          <button class='icon icon-close-light' onClick={closeModal}></button>
        </div>

        <div class='window-modal-body'>
          <h3>Build Your Profile 🎉</h3>

          {/* Country */}
          <div class='setup-profile-block'>
            {countryCompleteElement}
            <div class={`flex-column setup-profile-block-inner ${countryClass}`}>
              <p>Show the community a flag <span class='nobr'>of your home country</span></p>
              <div class='profile-input-wrapper'>
                <div class='profile-input'>
                  <select
                    type='select'
                    defaultValue={askCountry}
                    onChange={handleCountryChange}
                  >
                    <option value=''>Select</option>
                    {countryList.map((language, index) =>
                      <option key={index} value={language.value}>{language.name}</option>
                    )};
                  </select>
                </div>
              </div>
            </div>
          </div>


          {/* Native Language */}
          <div class='setup-profile-block'>
            {nativeCompleteElement}
            <div class={`flex-column setup-profile-block-inner ${nativeClass}`}>
              <p>Set your native language <span class='nobr'>to enable translations</span></p>
              <div class='profile-input-wrapper'>
                <div class='profile-input'>
                  <select
                    type='select'
                    defaultValue={askNativeLanguage}
                    onChange={handleLanguageChange}
                  >
                    <option value=''>Select</option>
                    {languageCodes.map((language, index) =>
                      <option key={index} value={language.value}>{language.name}</option>
                    )};
                  </select>
                </div>
              </div>
            </div>
          </div>


          {/* Avatar */}
          <div class='setup-profile-block'>
            {avatarCompleteElement}
            <div class={`flex-column setup-profile-block-inner ${avatarClass}`}>
              <p>Add your profile picture <span class='nobr'>to brighten things up</span></p>
              {avatarElement}
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default DashboardProfileSetupModal;