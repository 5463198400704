import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initialized: false,
  publicProfileId: null,
  firstName: "Loading ...",
  lastName: " ",
  email: "Loading ...",
  avatarUrl: 'https://files.flowspeak.io/files/default-avatar.png',
  country: null,
  languageLevelName: "Loading ...",
  languageLevelId: 1,
  interests: [],
  nativeLanguage: 'en',
  autoplayAudio: false,
  reminderFrequency: null,
  shareResponses: false,
  requestFeedback: false,
  includeAudioInShare: false,

  isFirstWeek: false,

  courseCanSkipAhead: false,

  notificationDailyReminders: false,
  notificationCommunityFeedback: false,
  notificationCommunityWeekDigest: false,
  notificationEncouragementEmails: false,
  notificationFlowPromotions: false,

  notifications: {},

  organization: null,
  organizationAdmin: false,
  specialDiscount: null,
  promoCode: null,

  orgLogo: null,

  hasPremiumSubscription: false,
  subscriptionName: null,
  subscription: {
    hasPremium: false,
    name: 'Community Member',
    autoUpgrade: false,
    autoUpgradeMessage: null,
    premiumTrial: false
  },

  badges: [],
  certificates: [],

  challenge: {},

  pricingTier: 1,

  infoCard: null,
  phonePrompt: null,

  score: {
    score: 'Loading ...',
    // score: '',
    streak_day_count: 0,
    lessons_completed_count: 0,
    community_interaction_count: 0
  },

  smsPayload: null,
  registerType: null
}

export const name = 'profile';

const profileSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    initializeProfile: (state, action) => {
      state.initalized = action.payload.initialized;
      state.publicProfileId = action.payload.publicProfileId;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.avatarUrl = action.payload.avatarUrl;
      state.country = action.payload.country;
      state.languageLevelName = action.payload.languageLevelName;
      state.languageLevelId = action.payload.languageLevelId;
      state.interests = action.payload.interests;
      state.nativeLanguage = action.payload.nativeLanguage;
      state.autoplayAudio = action.payload.autoplayAudio;

      state.hasPremiumSubscription = action.payload.hasPremiumSubscription;
      state.subscriptionName = action.payload.subscriptionName;
      state.subscription = action.payload.subscription;

      state.badges = action.payload.badges;
      state.certificates = action.payload.certificates;
      state.challenge = action.payload.challenge;

      state.pricingTier = action.payload.pricingTier;

      state.infoCard = action.payload.infoCard;
      state.phonePrompt = action.payload.phonePrompt;

      state.reminderFrequency = action.payload.reminderFrequency;
      state.shareResponses = action.payload.shareResponses;
      state.requestFeedback = action.payload.requestFeedback;
      state.includeAudioInShare = action.payload.includeAudioInShare;

      state.isFirstWeek = action.payload.isFirstWeek;

      state.courseCanSkipAhead = action.payload.courseCanSkipAhead;

      state.notificationDailyReminders = action.payload.notificationDailyReminders;
      state.notificationCommunityFeedback = action.payload.notificationCommunityFeedback;
      state.notificationCommunityWeekDigest = action.payload.notificationCommunityWeekDigest;
      state.notificationEncouragementEmails = action.payload.notificationEncouragementEmails;
      state.notificationFlowPromotions = action.payload.notificationFlowPromotions;

      state.notifications = action.payload.notifications;

      state.organization = action.payload.organization;
      state.organizationAdmin = action.payload.organizationAdmin;
      state.specialDiscount = action.payload.specialDiscount;
      state.promoCode = action.payload.promoCode;
      state.orgLogo = action.payload.orgLogo;

      state.askToShareWithOrg = action.payload.askToShareWithOrg;

    },

    setHasPremiumSubscription: (state, action) => {
      state.hasPremiumSubscription = action.payload.hasPremiumSubscription;
    },

    setScore: (state, action) => {
      state.score = action.payload;
    },

    updateField: (state, action) => {
      // state.xxx = action.payload.hasPremiumSubscription;
      state[action.payload.field] = action.payload.value;
    },

  },
});

export const { actions, reducer } = profileSlice;
export default profileSlice;



// ---

// export function profileReducer(state = initialSate, action) {

//   // if( action.type === 'initializeProfile') {
//   //   return {
//   //     ...state,
//   //     initialized: action.payload.initialized,
//   //     publicProfileId: action.payload.publicProfileId,
//   //     firstName: action.payload.firstName,
//   //     lastName: action.payload.lastName,
//   //     email: action.payload.email,
//   //     avatarUrl: action.payload.avatarUrl,
//   //     country: action.payload.country,
//   //     languageLevelName: action.payload.languageLevelName,
//   //     languageLevelId: action.payload.languageLevelId,
//   //     interests: action.payload.interests,
//   //     nativeLanguage: action.payload.nativeLanguage,
//   //     autoplayAudio: action.payload.autoplayAudio,

//   //     hasPremiumSubscription: action.payload.hasPremiumSubscription,
//   //     subscriptionName: action.payload.subscriptionName,
//   //     subscription: action.payload.subscription,

//   //     badges: action.payload.badges,
//   //     certificates: action.payload.certificates,
//   //     challenge: action.payload.challenge,

//   //     pricingTier: action.payload.pricingTier,

//   //     infoCard: action.payload.infoCard,
//   //     phonePrompt: action.payload.phonePrompt,

//   //     reminderFrequency: action.payload.reminderFrequency,
//   //     shareResponses: action.payload.shareResponses,
//   //     requestFeedback: action.payload.requestFeedback,
//   //     includeAudioInShare: action.payload.includeAudioInShare,

//   //     isFirstWeek: action.payload.isFirstWeek,

//   //     courseCanSkipAhead: action.payload.courseCanSkipAhead,

//   //     notificationDailyReminders: action.payload.notificationDailyReminders,
//   //     notificationCommunityFeedback: action.payload.notificationCommunityFeedback,
//   //     notificationCommunityWeekDigest: action.payload.notificationCommunityWeekDigest,
//   //     notificationEncouragementEmails: action.payload.notificationEncouragementEmails,
//   //     notificationFlowPromotions: action.payload.notificationFlowPromotions,

//   //     notifications: action.payload.notifications,

//   //     organization: action.payload.organization,
//   //     organizationAdmin: action.payload.organizationAdmin,
//   //     specialDiscount: action.payload.specialDiscount,
//   //     promoCode: action.payload.promoCode,
//   //     orgLogo: action.payload.orgLogo
//   //   };
//   // }

//   // if( action.type === 'updateField') {
//   //   return {
//   //     ...state,
//   //     [action.payload.field]: action.payload.value
//   //   }
//   // }

//   // if( action.type === 'setHasPremiumSubscription') {
//   //   return {
//   //     ...state,
//   //     hasPremiumSubscription: action.payload.hasPremiumSubscription
//   //   }
//   // }

//   // if( action.type === 'setScore') {
//   //   return {
//   //     ...state,
//   //     score: action.payload
//   //   }
//   // }


//   return state;
// }