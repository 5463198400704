import React from "react";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import '../../styles/explore.css';

import { reportError } from "../../functions/sentry";
import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import * as api from "../utilities/auth/authApi";
import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import LoadingBasic from "../utilities/loadingbasic";
import SeriesList from "./seriesList";
import SeriesFilters from "./seriesFilters";
import ExploreBlankState from "./exploreBlankState";

import allSeriesSlice from "../../store/allseries";

import fadeInMainBody from "../../functions/fadeinmainbody";

const initialFilters = {level: null, lessonType: null, searchTerm: null, activeFilter: false};


function Explore() {

  // Define variables
  const dispatch = useDispatch();
  const allSeriesLoaded = useSelector((state) => state.allSeries.initialized);
  const allSeries = useSelector((state) => state.allSeries.series);
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [filters, setFilters] = useState(initialFilters);
  const [blankStateElement, setBlankStateElement] = useState(null);


  // Page load actions
  useEffect(() => {

    fadeInMainBody();

    if( !allSeriesLoaded ) {
      api.getAllSeries().then((response) => {

        if( response.data ) {

          const payload = {
            initialized: true,
            series: response.data
          }

          // Update the redux store
          // dispatch({ type: 'initializeAllSeries', payload: payload });
          dispatch(allSeriesSlice.actions.initializeAllSeries(payload));
        }
      }).then(() => {
        setLoading(false);
      }).catch((error) => {
        reportError(error);
      });
    } else {
      setLoading(false);
    }

  }, [allSeriesLoaded, dispatch]);


  // Define functions
  function filterByType(value) {
    setFilters({
      ...filters,
      displayLessonType: (value === 'all' ? null : value),
      activeFilter: true
    });
    checkForBlankState();

    Mixpanel.track('Event: Filter Library', {'Type': value});
  }

  function filterByLevel(value) {
    setFilters({
      ...filters,
      level: (value === 'all' ? null : value),
      activeFilter: true
    });
    checkForBlankState();

    Mixpanel.track('Event: Filter Library', {'Level': value});
  }

  function filterByTerm(value) {
    setFilters({
      ...filters,
      searchTerm: (value === '' ? null : value.toLowerCase()),
      activeFilter: true
    });
    checkForBlankState();
  }

  function clearFilters() {
    document.getElementById('filter-lesson-type').value = 'All';
    document.getElementById('filter-level').value = 'All';
    document.getElementById('filter-search-term').value = '';

    setFilters(initialFilters);
    checkForBlankState();
  }

  function checkForBlankState() {
    setTimeout(() => {
      const wrappers = document.getElementsByClassName('series-wrapper');
      const count = wrappers.length;
      if( count > 0 ) {
        setBlankStateElement(null);
      } else {
        setBlankStateElement(<ExploreBlankState />);
      }
    }, 150);
  }


  return (
    <div class='wrapper gray-background'>
      <Nav />

      <div class='main-body'>
        <HeaderMain title="Library" />
        <div class='main-body-content series'>

          <SeriesFilters
            filterByType={filterByType}
            filterByLevel={filterByLevel}
            filterByTerm={filterByTerm}
            clearFilters={clearFilters}
            activeFilter={filters.activeFilter}
          />

          {loading}

          {allSeries.map((series, index) =>
            <SeriesList
              key={'series-' + index}
              series={series}
              filters={filters}
            />
          )}

          {blankStateElement}

        </div>
      </div>

      <BottomNav />

    </div>
  );
}

export default Explore;