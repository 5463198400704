import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  lessonData: {},
  isSaved: false,
  isIelts: false,
  isInitialized: false
}

export const name = 'expression';

const expressionSlice = createSlice({
  name,
  initialState,
  // The `reducers` field allows us define reducers and generate associated actions
  reducers: {
    setExpression: (state, action) => {
      state.isInitialized = true;
      state.id = action.payload.id;
      state.lessonData = action.payload.lessonData;
      state.isSaved = action.payload.isSaved;
      state.isIelts = action.payload.isIelts;
    },

    setExpressionIsSaved: (state, action) => {
      state.isSaved = action.payload;
    },
  },
});

export const { actions, reducer } = expressionSlice;
export default expressionSlice;


// // ----
// export function ExpressionReducer(state = initialSate, action) {

//   if( action.type === 'setExpression') {
//     return {
//       ...state,
//       id: action.payload.id,
//       lessonData: action.payload.lessonData,
//       isSaved: action.payload.isSaved,
//       isIelts: action.payload.isIelts,
//       isInitialized: true,

//     };
//   }

//   if( action.type === 'setExpressionIsSaved') {
//     return {
//       ...state,
//       isSaved: action.payload
//     };
//   }

//   return state;
// }