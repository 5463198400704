import React from "react";
import { useState } from 'react';
import Avatar from 'react-avatar-edit'
import axios from 'axios';

import alertMessage from "../utilities/alertMessage";
import { getToken } from "../utilities/auth/tokenHelpers";
import AvatarImage from "../utilities/avatarImage";

const initialButtonClasses = {
  edit: 'clean-button edit-button',
  save: 'clean-button save-button is-hidden',
  cancel: 'clean-button cancel-button is-hidden',
}


function ProfileImage(props) {

  // Define variables
  const [editAvatar, setEditAvatar] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [buttonClass, setButtonClass] = useState(initialButtonClasses);
  const updateField = props.updateField;
  const avatarUrl = props.avatarUrl;


  // Define functions
  function enableField() {
    const editAvatarElement = (
      <div class='avatar-edit-wrapper'>
        <Avatar
          width={250}
          height={200}
          onCrop={onCrop}
          onBeforeFileLoad={onBeforeFileLoad}
          src={''}
          exportAsSquare={true}
          exportMimeType={'image/jpeg'}
        />
      </div>
    );

    setEditAvatar(editAvatarElement);

    const newButtonClass = {
      edit: 'clean-button edit-button is-hidden',
      save: 'clean-button save-button',
      cancel: 'clean-button cancel-button',
    }
    setButtonClass(newButtonClass);
  }

  function cancelEdit() {
    setEditAvatar(null);
    setButtonClass(initialButtonClasses);
  }

  function saveData() {
    // Show the loading spinner
    const newButtonClass = {
      edit: 'clean-button edit-button is-hidden',
      save: 'clean-button save-button is-loading',
      cancel: 'clean-button cancel-button',
    }
    setButtonClass(newButtonClass);

    handleImageData(imageData);
  }

  function onCrop(data) {
    setImageData(data);
  }

  function onBeforeFileLoad(element) {
    if(element.target.files[0].size > 3000000){
      alertMessage('Please choose a file that is less than 3MB', 'error');
      element.target.value = "";
    };
  }

  function handleImageData(base64) {

    fetch(base64).then(res => res.blob())
    .then(blob => {

      const formData = new FormData();
      formData.append(`user[avatar]`, blob, 'avatar');

      const config = {
        method: 'post',
        url: 'user/update_personal_information',
        headers: { authorization: `Bearer ${getToken()}`, "Content-Type": "multipart/form-data" },
        data : formData
      };

      // Send the data to the server
      return axios(config).then(function (response) {
        if( response.data.success ) {

          updateField('avatarUrl', response.data.avatarUrl);

          setEditAvatar(null);
          setButtonClass(initialButtonClasses);
          alertMessage('Profile successfully updated');
        } else {
          alertMessage(response.data.error, 'error');
        }

      })
      .catch(function (error) {
        alertMessage('Sorry, there was an error', 'error');
      });

    }).finally(() => {
      setButtonClass(initialButtonClasses);
    });

  }


  // Screen components
  let avatar;
  if( avatarUrl ) {
    avatar = (
    <div class='avatar-wrapper'>
      <AvatarImage avatarUrl={avatarUrl} size={50} />
    </div>
    );
  }


  return (
    <div class='profile-input-wrapper'>
      <label>Avatar</label>
      <div class='profile-input'>
        {avatar}
        <div class='profile-input-buttons'>
          <button type='button' class={buttonClass.edit} onClick={enableField}>
            <span class='icon icon-edit'></span>
            <span style={{marginLeft: '5px'}}>edit</span>
          </button>
          <button type='button' class={buttonClass.save} onClick={saveData}>save</button>
          <button type='button' class={buttonClass.cancel} onClick={cancelEdit}>cancel</button>
        </div>
      </div>
      {editAvatar}
    </div>
  );
}

export default ProfileImage;