import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import RenderHtmlText from "../utilities/renderhtmltext";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import AiTalkMessageScore from "./aiTalkMessageScore";
import ScoreDetailsBiteLite from "../utilities/learning_module/scoreDetailsBiteLite";
import AvatarWithAudio from "../utilities/avatars/avatarWithAudio";


function Message(props) {

  // Define variables
  const isIelts = useSelector((state) => state.aiTalk.isIelts);
  const [translatedFeedback, setTranslatedFeedback] = useState(null);
  const [modal, setModal] = useState(null);
  const [improveText, setImproveText] = useState(null);
  const messageIndex = props.messageIndex;
  const iconUrl = props.iconUrl;
  const avatarUrl = props.avatarUrl;
  const message = props.message;
  const role = message.role;
  const content = message.content;
  const audioUrl = message.audioUrl;
  const display = message.display;
  const responseObject = message.responseObject;
  const generateTips = props.generateTips;

  const translateToggle = props.translateToggle;
  const translation = props.translation ? props.translation.translation : null;


  // Define functions
  function openModal() {
    setModal(
      <AiTalkMessageScore
        responseObject={responseObject}
        closeModal={closeModal}
        improveText={improveText}
        setImproveText={setImproveText}
        generateTips={generateTips}
        messageIndex={messageIndex}
        isIelts={isIelts}
        translatedFeedback={translatedFeedback}
        setTranslatedFeedback={setTranslatedFeedback}
      />
    );
    Mixpanel.track('Event: AI Talk see tips');
  }

  function closeModal(){
    setModal(null);
  }

  // Screen components
  let messageElement;
  if( role === 'user' ) {

    let scoreElement;
    if( responseObject ) {

      // let fluencyScore;
      let overallScore;
      // let overallScoreWord;
      if( responseObject?.overall?.percent ) {
        // fluencyScore = `${responseObject.fluency.percent}% Fluent`;
        overallScore = `${responseObject.overall.percent}% `;
        // overallScoreWord = `${responseObject.overall.word} `;
        // if( isIelts ) {
        //   fluencyScore = `${responseObject.ieltsScores.fluencyAndCoherence}`;
        // }

        scoreElement = (
          <div class='flex-row aitalk-score-button-container'>
            <button
              type='button'
              class='clean-button aitalk-score-button'
              onClick={() => openModal()}
            >
              {/* {fluencyScore} <small>(tap for details)</small> */}
              {/* {overallScore} <small>(tap for details)</small> */}
              {/* {overallScoreWord} <small>(tap for details)</small> */}
              <ScoreDetailsBiteLite
                responseObject={responseObject}
                ieltsCourse={false}
              />
            </button>
          </div>
        );
      }
    }

    messageElement = (
      <div>
        <div class='aitalk-message-container learner'>
          <div class='flex-row aitalk-message'>
            <p>{content}</p>
          </div>
          <AvatarWithAudio
            type='learner'
            avatarUrl={avatarUrl}
            audioUrl={audioUrl}
          />
        </div>
        {scoreElement}
      </div>
    );

  } else if( role === 'assistant' ) {

    let translateElement;
    if( translateToggle && translation ) {
      translateElement = (
        <div class='translate-container fade-in-on-load'>
          <RenderHtmlText string={translation} />
        </div>
      );
    }

    messageElement = (
        <div class='aitalk-message-container assistant'>
          <AvatarWithAudio
            type='assistant'
            avatarUrl={iconUrl}
            audioUrl={audioUrl}
          />

          <div>
            <div class='flex-row aitalk-message'>
              <p>{content}</p>
            </div>
            {translateElement}
          </div>
        </div>
    );

  }

  if( display === false ) {
    messageElement = null;
  }


  return (
    <div>
      {modal}
      {messageElement}
    </div>
  );
}

export default Message;